<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li class="active"><a href="/company/com-data.html">基本資料</a></li>
        <li><a href="/company/location.html">地理位置</a></li>
        <li><a href="/company/business-hours.html">營業時間</a></li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- =========================================== -->
      <div id="comp-images-box">
        <div class="div-con-comp-img">
          <img
            src="@/components/contentPages/comData/images/CJ-company-1.webp"
            alt=""
          />
        </div>
        <div class="div-con-comp-img">
          <img
            src="@/components/contentPages/comData/images/CJ-company-2.webp"
            alt=""
          />
        </div>
      </div>
      <!-- =====公司基本資料table容器: 放 "company-data-table_project"項目tr容器  和  "company-data-table_content"內容tr容器 ====>tr>td== -->
      <table class="company-data-table">
        <tr class="back-color-1">
          <td class="table_project">商 號</td>
          <td>啓 鉅 商 行</td>
        </tr>
        <tr class="back-color-2">
          <td class="table_project">統 編</td>
          <td>01509588</td>
        </tr>
        <tr class="back-color-1">
          <td class="table_project">專 營</td>
          <td>電動機車,電動自行車,電動輔助自行車,腳踏車的銷售與維修服務。</td>
        </tr>
        <tr class="back-color-2">
          <td class="table_project">地 址</td>
          <td>33561 桃園市大溪區仁和東街121號 (麥當勞大溪員林店旁)。</td>
        </tr>
        <tr class="back-color-1">
          <td class="table_project">電 話</td>
          <td>03-3803701</td>
        </tr>
        <tr class="back-color-2">
          <td class="table_project">電 郵</td>
          <td>dasi.nicemotor@gmail.com</td>
        </tr>
        <tr class="back-color-1">
          <td class="table_project">科 目</td>
          <td>
            汽車修護(畢)，電氣焊(證)，電腦修護(專長班結)，java
            Program(結)，Linux Service(結)。
          </td>
        </tr>
        <tr class="back-color-2">
          <td class="table_project">經 歷</td>
          <td>
            桃園市育青汽車教練場(教練)，豊田汽車(退)作業標準票作成系統(程式編寫)。
          </td>
        </tr>
        <tr class="back-color-1">
          <td class="table_project">實 習</td>
          <td>錡明電動車,中華電動二輪車(銷售, 維修-原廠檢測電腦)。</td>
        </tr>
      </table>
      <!-- 電動車維修實習 -->
      <div class="practice-box">
        <div class="practice-img-box mobile-768">
          <img
            src="@/components//contentPages/comData/images/FreeOfUniformInvoice.avif"
            alt="本店免用統一發票"
          />
          <img
            src="@/components//contentPages/comData/images/Emoving_log.avif"
            alt="中華電動二輪車"
          />
        </div>
      </div>
      <!-- 課程 -->
      <div class="course-box mobile-768">
        <ul>
          <li>2019年前 中華EM-25 EM-50 EM-80 EM-100 -維修課程</li>
          <li>2019年07月05日 中華IE125普重機車-維修課程</li>
          <li>2019年07月25日 中華IE125普重機車-仁和店試乘車進駐</li>
          <li>2019年07月28日 中華IE125普重機車-銷售課程</li>
          <li>2020年08月17日 中華IE125(貨)普重機車 -實車拆裝檢修課程</li>
        </ul>
      </div>
      <!-- =========================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* =====基本資料(CJ-information.html)中欄設置===== */
/* ---------div 容器:放置img圖片--------- */
#comp-images-box {
  display: flex;
  width: 100%;
  gap: 10px;
}

.div-con-comp-img {
  width: 100%;
}

.div-con-comp-img img {
  /* ---設定img 容器邊角成圖形--- */
  border-radius: 160px;
  border: 1px solid rgb(216, 216, 216);
}

/* =====公司基本資料table容器: 放 "company-data-table_project"項目tr容器  和  "company-data-table_content"內容tr容器 ====>tr>td== */
.company-data-table {
  /* 字體設定 */
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-weight: bold; */

  /* 1rem=16px , 12pt,16pt(文字編輯器中惖12大小與16大小 */
  font-size: 14pt;
  /* 列高設定 */
  /* line-height: 20px; */
  /* 將兩個div容器水平排列 (預設靠左)*/
  /* display: flex; */
  width: 100%;
  margin: 10px 0 10px 0;
}

.company-data-table td {
  padding: 5px;
}

.table_project {
  font-weight: bolder;
  /* ====文字分散對齊==== */
  text-align-last: justify;
}

.back-color-2 {
  background-color: rgb(240, 240, 240);
}

/* 電動車維修實習 */
.practice-box,
.course-box {
  margin: 40px 0;
}

.practice-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.practice-img-box > img {
  max-width: 300px;
}

/* 課程 */
.course-box {
  display: flex;
}

.course-box > ul {
  width: 100%;
}
.course-box > ul > li {
  font-size: 14pt;
}

.course-box > div {
  width: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 10px 0;
}
@media screen and (max-width: 920px) {
  .company-data-table {
    font-size: 14pt;
  }
  .course-box > ul > li {
    font-size: 14pt;
  }
}
@media screen and (max-width: 768px) {
  .company-data-table {
    font-size: 12pt;
  }
  .course-box > ul > li {
    font-size: 12pt;
  }
}
@media screen and (max-width: 540px) {
  .company-data-table {
    font-size: 10pt;
  }
  .course-box > ul > li {
    font-size: 10pt;
  }
}
</style>
