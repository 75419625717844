<script>
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li class="active"><a href="/viewpoint/dasi-map.html">大溪景點</a></li>
        <li><a href="/viewpoint/gourmet-food.html">大溪美食</a></li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- ============================================== -->
      <!-- HTML ============大溪地圖====================== -->
      <div class="dasiMap">
        <img
          src="@/components/contentPages/viewpoint/images/DaxiAttractionsMap.avif"
          alt="大溪景點地圖"
        />
      </div>
      <!-- ============================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.dasiMap {
  width: 100%;
}

div.dasiMap > img {
  border-radius: 10px;
}
</style>
