<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li><a href="/company/com-data.html">基本資料</a></li>
        <li><a href="/company/location.html">地理位置</a></li>
        <li class="active">
          <a href="/company/business-hours.html">營業時間</a>
        </li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- =========================================== -->
      <div class="shop-hours">平日：星期一至星期五 10:00AM~9:00PM</div>

      <div class="shop-hours">假日：星期六至星期日 10:00AM~9:00PM</div>

      <div class="shop-confirm">( 六、日：為不定時休息,請先來電確認。 )</div>

      <div class="shop-hours">年休：年初一、二、三、四</div>
      <!-- =========================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#tab-box > div {
  margin: 10px 0px;
}
.shop-hours {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24pt;
  font-weight: bold;
}
.shop-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18pt;
  font-weight: bold;
  color: brown;
}
@media screen and (max-width: 920px) {
  .shop-hours {
    font-size: 20pt;
  }
  .shop-confirm {
    font-size: 14pt;
  }
}
@media screen and (max-width: 768px) {
  .shop-hours {
    font-size: 16pt;
  }
  .shop-confirm {
    font-size: 12pt;
  }
}
@media screen and (max-width: 540px) {
  .shop-hours {
    font-size: 12pt;
  }
  .shop-confirm {
    font-size: 8pt;
  }
}
</style>
