import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
//===================公司資料
// import CompanyData from "@/components/contentPages/CompanyData.vue";//===公司資料
import ComData from "@/components/contentPages/comData/ComData.vue";//公司資料 - 公司資料
import Location from "@/components/contentPages/comData/Location.vue";//公司資料 - 地理位置
import BusinessHours from "@/components/contentPages/comData/BusinessHours.vue";//公司資料 - 營業時間
//===================最新訊息
import News from "@/components/contentPages/News.vue";//最新訊息
//===================電車廠牌============================
//---中華-Emoving
// import Brand from "@/components/contentPages/Brand.vue";//===電車廠牌-Emoving
import Emoving from "@/components/contentPages/brand/e-moving/Emoving.vue";//===電車廠牌-Emoving
import Wasion from "@/components/contentPages/brand/wasion/Wasion.vue";//===電車廠牌-威 勝-wasion
import Sanno from "@/components/contentPages/brand/sanno/Sanno.vue";//===電車廠牌-山 王-sanno
import Nicerich from "@/components/contentPages/brand/nicerich/Nicerich.vue";//===電車廠牌-錡 明-nicerich

//===================二輪分類
// import TwoRouOfClass from "@/components/contentPages/TwoRouOfClass.vue";//===二輪分類
import Classification from "@/components/contentPages/twoRouOfClass/Classification.vue";//電動二輪車的分類
import Regulations from "@/components/contentPages/twoRouOfClass/Regulations.vue";//相關法規
import SafeToUse from "@/components/contentPages/twoRouOfClass/SafeToUse.vue";//防衛駕駛
//===================補助相關
import SubsidizeData from "@/components/contentPages/SubsidizeData.vue";//補助相關
//===================機車科試
// import MotorcycleTest from "@/components/contentPages/MotorcycleTest.vue";//機車科試
import RoadTest from "@/components/contentPages/motorcycleTest/RoadTest.vue";//路考
import WrittenTest from "@/components/contentPages/motorcycleTest/WrittenTest.vue";//筆試
//===================大溪景食
import DasiMap from "@/components/contentPages/viewpoint/DasiMap.vue";//大溪景點
import GourmetFood from "@/components/contentPages/viewpoint/GourmetFood.vue";//大溪美食
//===================政府機關
import GovernmentBody from "@/components/contentPages/GovernmentBody.vue";//政府機關
//===================網頁相關
import WebRelated from "@/components/contentPages/WebRelated.vue";//網頁相關
//=========404找不到網頁============
import NotFound from '@/components/NotFound.vue';



const routes = [
   // ========  最新訊息 ========  
   //********最新訊息--可能的名稱預先跳轉********
   {
    path: "/",
    redirect: "/news.html", 
      },
    {
      path: "/news",
      redirect: "/news.html", 
    },
    //********最新訊息--可能的名稱預先跳轉********end 
  {//-----最新訊息
    path: "/news.html",
    name: "news",
    component: News,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "桃園電動車,大溪電動車,電動機車,電動二輪車,啓鉅電動車,啓鉅車業,啓鉅商行,中華電動二輪車,威勝電動車,山王電動車,錡明電動車,電動二輪車分類,電動二輪相關法規,防衛駕駛,電動車相關補助,機車考照,機車筆試,機車路考,大溪景點,大溪美食,政府機關,網頁相關",
      description: "電動二輪車的最新訊息"
    }
  },
// ========  公司資料 ========  
//********公司資料--可能的名稱預先跳轉********
   {
      path: "/company",
      redirect: "/company/com-data.html", 
   },
   {
    path: "/company/com-data",
    redirect: "/company/com-data.html", 
    },
    {
      path: "/company/location",
      redirect: "/company/location.html", 
    },
    {
      path: "/company/business-hours",
      redirect: "/company/business-hours.html", 
    },
//********公司資料--可能的名稱預先跳轉********end

  {//-----公司資料
    path: "/company/com-data.html",
    name: "CompanyData",
    component: ComData,
       meta: {// 2.重寫頭頁meta的描述
      keywords: "桃園電動車,大溪電動車,電動機車,電動二輪車,啓鉅電動車,啓鉅車業,啓鉅商行,中華電動二輪車,威勝電動車,山王電動車,錡明電動車,電動二輪車分類,電動二輪相關法規,防衛駕駛,電動車相關補助,機車考照,機車筆試,機車路考,大溪景點,大溪美食,政府機關,網頁相關",
      description: "公司基本資料:商號:啓鉅商行,統編:01509588,專營:電動機車,電動自行車,電動輔助自行車,腳踏車的銷售與維修服務。,地址:33561 桃園市大溪區仁和東街121號(麥當勞大溪員林店旁)。,電話:03-3803701"
    }
  },
  {//-----公司資料 - 地理位置
    path: "/company/location.html",
    name: "location",
    component: Location,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "桃園電動車,大溪電動車,電動二輪車,啓鉅電動車,啓鉅車業,中華電動二輪車,威勝電動車,山王電動車,錡明電動車,電動二輪車分類,電動二輪相關法規,防衛駕駛,電動車相關補助,機車考照,機車筆試,機車路考,大溪景點,大溪美食,政府機關,網頁相關",
      description: "啓鉅車業的地理位置。"
    }
  },
  {//-----公司資料 - 營業時間
    path: "/company/business-hours.html",
    name: "business-hours",
    component: BusinessHours,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "桃園電動車,大溪電動車,電動二輪車,啓鉅電動車,啓鉅車業,中華電動二輪車,威勝電動車,山王電動車,錡明電動車,電動二輪車分類,電動二輪相關法規,防衛駕駛,電動車相關補助,機車考照,機車筆試,機車路考,大溪景點,大溪美食,政府機關,網頁相關",
      description: "啓鉅車業的營業時間。"
    }
  },
 

  // ========  電車廠牌  ======== 
  //********電車廠牌--可能的名稱預先跳轉********
    {
      path: "/brand",
      redirect: "/brand/e-moving.html", 
   }, 
  {
    path: "/brand/e-moving",
    redirect: "/brand/e-moving.html", 
 },
  {
    path: "/brand/wasion",
    redirect: "/brand/wasion.html", 
 },
   {
    path: "/brand/sanno",
    redirect: "/brand/sanno.html", 
 },
   {
    path: "/brand/nicerich",
    redirect: "/brand/nicerich.html", 
 },
 //********電車廠牌--可能的名稱預先跳轉********end

    { //----電車廠牌---中華-Emoving
    path: "/brand/e-moving.html",
    name: "brand",
    component: Emoving,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "e-moving,中華電動二輪車,中華電動車:車種:EZ-R(換電式-普通重型機車-白底黑字牌) ,勁炫-125(快充式-普通重型機車-白底黑字牌),EZ-1(換電式-普通輕型機車-綠底白字牌),Shine(家充式-微型電動二輪車-白底綠字牌),Bobe(家充式-微型電動二輪車-白底綠字牌)..IE-125(快充式-普通重型機車-白底黑字牌)",
      description: "介紹 e-moving 中華電動二輪車 目前上市的所有車種。"
    }
  },
  {//----電車廠牌---威勝-Wasion
    path: "/brand/wasion.html",
    name: "wasion",
    component: Wasion,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "wasion,威勝電動二輪車,威勝電動車",
      description: "車種:(家充式-微型電動二輪車-白底綠字牌),(家充式-電動輔助自行車-電輔)"
    }
  },
  {//----電車廠牌-山王-sanno
    path: "/brand/sanno.html",
    name: "sanno",
    component: Sanno,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "sanno,山王電動二輪車,山王電動車",
      description: "車種:(家充式-微型電動二輪車-白底綠字牌),(家充式-電動輔助自行車-電輔)"
    }
  },
  
  {//----電車廠牌-錡明-nicerich
    path: "/brand/nicerich.html",
    name: "nicerich",
    component: Nicerich,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "nicerich,錡明電動二輪車,錡明電動車",
      description: "車種:(家充式-微型電動二輪車-白底綠字牌),(家充式-電動輔助自行車-電輔)"
    }
  },


// ========  二輪分類   ============
  //********二輪分類 --可能的名稱預先跳轉********
   {
      path: "/tworou-of-class",
      redirect: "/tworou-of-class/classification.html", 

   },
  {
    path: "/tworou-of-class/classification",
    redirect: "/tworou-of-class/classification.html", 
 }, 
   {
    path: "/tworou-of-class/regulations",
    redirect: "/tworou-of-class/regulations.html", 
 }, 
 {
  path: "/tworou-of-class/safe-to-use",
  redirect: "/tworou-of-class/safe-to-use.html", 
}, 
 //********二輪分類 --可能的名稱預先跳轉********end

  {//------ 二輪分類 -> 電動二輪車的分類
    path: "/tworou-of-class/classification.html",
    name: "TwoRouOfClass",
    component: Classification, 
         meta: {// 2.重寫頭頁meta的描述
        keywords: "電動二輪車的分類:慢速車:電動輔助自行車(簡稱:電輔),微型電動二輪車。電動機車:小型輕型,普通輕型,普通重型,大型重型。註:非本大類依法審驗合格之車種皆無路權(依法不得行駛一般道路)....等之相關法令說明。",
        description: "電動二輪車的分類說明。"
      }
  },
  {//------ 二輪分類 -> 相關法規
    path: "/tworou-of-class/regulations.html",
    name: "Regulations",
    component: Regulations,     
       meta: {// 2.重寫頭頁meta的描述
        keywords: "電動二輪車的相關法規:輔助自行車及微型電動二輪車型式安全審驗管理辦法。道路交通管理處罰條例。....等之相關法令說明。",
        description: "電動二輪車的相關法規說明。"
      }
  },
  {// ----- 二輪分類 -> 防衛駕駛
    path: "/tworou-of-class/safe-to-use.html",
    name: "SafeToUse",
    component: SafeToUse,
    meta: {// 2.重寫頭頁meta的描述
     keywords: "騎乘電動二輪車,騎乘機車,基本的防衛駕駛觀念:騎乘機車的安全注意事項。使用電動二輪車應有的觀念。",
     description: "騎乘電動二輪車基本的防衛駕駛觀念。"
   }
  },



// ========  補助相關 ========   
  //********補助相關 --可能的名稱預先跳轉********
  {
    path: "/subsidize-data",
    redirect: "/subsidize-data.html", 
 },
 //********補助相關 --可能的名稱預先跳轉********end
  { 
    path: "/subsidize-data.html",
    name: "subsidize-data",
    component: SubsidizeData, 
       meta: {// 2.重寫頭頁meta的描述
     keywords: "桃園市政府環境保護局,桃園市低污染車輛補助實施計劃。桃園市環保局補助線上申辦審查系統...等相關補助",
     description: "政府對電動二輪車的相關補助"
   }
  },

// ========  機車科試 ======== 
  //********機車科試 --可能的名稱預先跳轉********
   {
    path: "/motor-test",
    redirect: "/motor-test/written-test.html", 
 },
  {
    path: "/motor-test/written-test",
    redirect: "/motor-test/written-test.html", 
 },
   {
    path: "/motor-test/road-test",
    redirect: "/motor-test/road-test.html", 
 },
 //********機車科試 --可能的名稱預先跳轉********end
 { //----- 筆試
    path: "/motor-test/written-test.html",
    name: "written-test",
    component: WrittenTest,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "機車考試,機車筆試:題庫,法規及標誌,標線,號誌的選擇題與是非題。交通部公路總局汽機車線上隨機模擬考系統。",
      description: "機車筆試相關題庫與線上隨機模擬考。"
    }
  },

  {//----- 路考
    path: "/motor-test/road-test.html",
    name: "road-test",
    component: RoadTest,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "機車考試,機車路考,普通重型暨輕型機車新式路考考驗科(項)目場地圖。含:監理所機車路考說明。大溪與龍潭區機車考照練習場: 桃園市龍潭區 大平里集會所 (桃園市龍潭區永平路80號)",
      description: "機車路考相關考驗科目與場地說明。"
    }
  },
// ========  大溪景食 ======== 
  //********大溪景食 --可能的名稱預先跳轉********
  { 
  path: "/viewpoint",
  redirect:  "/viewpoint/dasi-map.html",
},
  {
    path: "/viewpoint/dasi-map",
    redirect: "/viewpoint/dasi-map.html", 
 },
   {
    path: "/viewpoint/gourmet-food",
    redirect: "/viewpoint/gourmet-food.html", 
 },
//********大溪景食 --可能的名稱預先跳轉********end
{ //----- 大溪景點
  path: "/viewpoint/dasi-map.html",
  name: "dasi-map",
  component: DasiMap,
  meta: {// 2.重寫頭頁meta的描述
    keywords: "桃園市大溪區景點:石門水庫,大溪老街,山豬湖自然生態公園,中圧調整池,月眉人工濕地生態公園,李騰芳古宅,大溪景觀自行車道,大溪蓮座山觀音寺...等多處景點",
    description: "大溪一些好玩的觀光景點:石門水庫,大溪老街,山豬湖自然生態公園...等的列圖參考。"
  }
},
{ //----- 大溪美食
  path: "/viewpoint/gourmet-food.html",
  name: "gourmet-food",
  component: GourmetFood,
  meta: {// 2.重寫頭頁meta的描述
    keywords: "桃園市大溪區美食:元味美食,食六仁中式餐館,七間町,二畝園麵館,木盒子窯烤披薩....",
    description: "記錄桃園市大溪區的一些好吃的美食店。"
  }
},
// ========  政府機關 ========  
  //********政府機關 --可能的名稱預先跳轉********
  { 
    path: "/government-body",
    redirect:  "/government-body.html",
  },  
   //********政府機關 --可能的名稱預先跳轉********end
{
  path: "/government-body.html",
  name: "government-body",
  component: GovernmentBody,
    meta: {// 2.重寫頭頁meta的描述
    keywords: "政府機關:經濟部,交通部公路總局,工業技術研究院,行政院環境保護署,桃園市政府,桃園市政府環境保護局,桃園市大溪區公所..等。",
    description: "記錄與本店較相關之政府機關。"
  }
},
 // ========  網頁相關 ======== 
   //********網頁相關 --可能的名稱預先跳轉********
   { 
    path: "/web-related",
    redirect:  "/web-related.html",
  },  
  //********網頁相關 --可能的名稱預先跳轉********end
  { 
    path: "/web-related.html",
    name: "web-related",
    component: WebRelated,
    meta: {// 2.重寫頭頁meta的描述
      keywords: "網頁相關:網站管理,虛擬主機,註冊網域,網頁工具。",
      description: "記錄網頁相關。"
    }
  },
 


 // 通配符路由规则，匹配所有未匹配的路径
 {//=========404找不到網頁============
  path: "/:catchAll(.*)",
  component: NotFound, // 404页面的组件
},

];


//=============添加導航守衛，例如beforeEach守衛：======================
// 2.重寫頭頁meta的描述(配合meta: { keywords: "XXX",description: "XXX" })==
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const meta = to.meta; // 獲取頁面的 meta 數據
  const currentUrl = window.location.origin + to.path;// 獲取主機名  +  path(各頁寫的路由)
  const description = meta.description || '啓鉅車業:位於桃園市大溪區的電動二輪車銷售與維修之經銷商。主要以中華電動二輪車銷售與維修為主，威勝電動車、山王電動車...等為輔。本店銷售車種皆為政府審驗合格且具有路權,合法合規之車種。'; // 設置默認描述
  const keywords = meta.keywords || '桃園電動車,大溪電動車,電動二輪車,啓鉅電動車,啓鉅車業,中華電動二輪車,威勝電動車,山王電動車,錡明電動車,電動二輪車分類,電動二輪相關法規,防衛駕駛,電動車相關補助,機車考照,機車筆試,機車路考,大溪景點,大溪美食,政府機關,網頁相關"'; // 設置默認關鍵詞
  const ogUrl = meta.ogUrl || currentUrl; // 設置默認網站名稱(自動取用URL)
  // const ogUrl = meta.ogUrl || currentUrl + 'brand/e-moving'; // 设置默认 og:url
  // 設置 description 的 meta 標籤
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  }
  // 設置 keywords 的 meta 標籤
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  }
    // 設置 inWebName (og:url)的 meta 標籤
    const metaOgUrl = document.querySelector('meta[property="og:url"]');
    if (metaOgUrl) {
      metaOgUrl.setAttribute('content', currentUrl);
    }
  // 設置其他需要修改的 meta 標籤
  // 繼續導航
  next();
});


// router.beforeEach((to, from, next) => {
//   // 獲取換頁後的真實URL
//   const currentUrl = window.location.href;

//   // 設定 og:url 的 meta 標籤
//   const metaOgUrl = document.querySelector('meta[property="og:url"]');
//   if (metaOgUrl) {
//     metaOgUrl.setAttribute('content', currentUrl);
//   }

//   // 繼續導航
//   next();
// });

//=============添加導航守衛，例如beforeEach守衛：======================End




export default router;
