<script>
// 引入 pagesDynamicClick.js 文件:這是控制多組件(多頁面)之點擊放大縮小的javascript
import { onMounted } from "vue";
import pagesDynamicClick from "@/components/contentPages/utils/pagesDynamicClick.js";
//引入各店家
import Food_1 from "@/components/contentPages/viewpoint/gourmetFood/Food-1.vue";
import Food_2 from "@/components/contentPages/viewpoint/gourmetFood/Food-2.vue";
import Food_3 from "@/components/contentPages/viewpoint/gourmetFood/Food-3.vue";
import Food_4 from "@/components/contentPages/viewpoint/gourmetFood/Food-4.vue";
import Food_5 from "@/components/contentPages/viewpoint/gourmetFood/Food-5.vue";
import Food_6 from "@/components/contentPages/viewpoint/gourmetFood/Food-6.vue";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  components: {
    Food_1,
    Food_2,
    Food_3,
    Food_4,
    Food_5,
    Food_6,
  },
  setup(props) {
    const storeModels = [
      { component: Food_1, isExpanded: false },
      { component: Food_2, isExpanded: false },
      { component: Food_3, isExpanded: false },
      { component: Food_4, isExpanded: false },
      { component: Food_5, isExpanded: false },
      { component: Food_6, isExpanded: false },
    ];
    const toggleExpand = (index) => {
      // console.log("我進來了");

      storeModels.forEach((model, i) => {
        // console.log(model, i);
        model.isExpanded = i === index && !model.isExpanded;
      });
    };
    // 在pagesDynamicClick組件掛載後執行點擊放大縮小的邏輯
    onMounted(() => {
      pagesDynamicClick();
    });
    return {
      props,
      storeModels,
      toggleExpand,
    };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li><a href="/viewpoint/dasi-map.html">大溪景點</a></li>
        <li class="active">
          <a href="/viewpoint/gourmet-food.html">大溪美食</a>
        </li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- ============================================== -->
      <div class="parent">
        <div class="child" @click="toggleExpand(0)">
          <div id="goodFood-1"><Food_1 /></div>
        </div>
        <div class="child" @click="toggleExpand(1)">
          <div id="goodFood-2"><Food_2 /></div>
        </div>
        <div class="child" @click="toggleExpand(2)">
          <div id="goodFood-3"><Food_3 /></div>
        </div>
        <div class="child" @click="toggleExpand(3)">
          <div id="goodFood-4"><Food_4 /></div>
        </div>
        <div class="child" @click="toggleExpand(4)">
          <div id="goodFood-5"><Food_5 /></div>
        </div>
        <div class="child" @click="toggleExpand(5)">
          <div id="goodFood-6"><Food_6 /></div>
        </div>
      </div>
      <!-- ============================================== -->
    </div>
  </div>
</template>

<style lang="scss">
.parent {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;

  > .child {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;
    // transition: all 0.3s ease-in-out;
    background-color: #fcfcfc;
    padding: 10px;

    &:hover {
      transform: scale(1);
      cursor: pointer;
    }
  }
  > .child.toMaxBox:hover {
    transform: scale(1);
  }
}
// .parent {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 15px;
//   position: relative;
// }

// .child {
//   max-width: 200px;
//   max-height: 200px;
//   overflow: hidden;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   transition: all 0.3s ease-in-out;
//   background-color: #fcfcfc;
//   padding: 10px;
// }

// .child:hover {
//   transform: scale(1.1);
//   cursor: pointer;
// }

// .child.toMaxBox:hover {
//   transform: scale(1);
// }
</style>
