<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <hr class="style-two" />
  <div class="heading"><span>網站管理</span></div>
  <table>
    <tr>
      <td>
        Google網站管理員<br />
        <a
          href="https://search.google.com/search-console/welcome"
          target="_blank"
          >Google Search Console</a
        >
      </td>
      <td>
        網站流量統計<br /><a
          href="https://analytics.google.com/analytics/web/"
          target="_blank"
          >Google Analytics</a
        >
      </td>
    </tr>
    <tr>
      <td>
        Bing 網站管理員工具<br />
        <a href="https://www.bing.com/webmasters/" target="_blank"
          >Bing Webmaster tools</a
        >
      </td>
      <td>
        網站效能分析工具<br /><a
          href="https://pagespeed.web.dev/"
          target="_blank"
          >PagSpeed Insights</a
        >
      </td>
    </tr>
  </table>

  <hr class="style-two" />
  <div class="heading"><span>虛擬主機</span></div>
  <table>
    <tr>
      <td>
        <a href="https://lionfree.net/" target="_blank">Lionfree 獅子(台灣)</a>
      </td>
      <td>
        <a href="https://www.netlify.com/" target="_blank">netlify (美國)</a>
      </td>
    </tr>
  </table>

  <hr class="style-two" />
  <div class="heading"><span>註冊網域</span></div>
  <table>
    <tr>
      <td></td>
      <td></td>
    </tr>
  </table>

  <hr class="style-two" />
  <div class="heading"><span>網頁工具</span></div>
  <table>
    <tr>
      <td>
        <a href="https://csscompressor.com/" target="_blank">CSS Compressor</a>
      </td>
      <td>
        <a href="https://skalman.github.io/UglifyJS-online/" target="_blank"
          >JS Compressor</a
        >
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://coding.tools/tw/html-minifier" target="_blank"
          >HTML Compressor</a
        >
      </td>
      <td></td>
    </tr>
  </table>

  <hr class="style-two" />
  <div class="heading"><span>作品集</span></div>
  <table>
    <tr>
      <td>
        <a href="https://exercise.lionfree.net/" target="_blank"
          >AI畫廊 - DrawAI</a
        >
      </td>
      <td>
        <a href="https://let-him-cook.netlify.app/" target="_blank"
          >Let Him Cook 匠心料理</a
        >
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
    </tr>
  </table>
</template>

<style lang="scss" scoped>
div.heading {
  display: flex;
  justify-content: center;
  transform: translateY(-5px);
}

div.heading span {
  font-size: 14pt;
  padding: 5px 10px 5px 10px;
  background-color: rgb(87, 89, 90);
  border-radius: 0 0 5px 5px;
  color: #fcfcfc;
}

table {
  width: 100%;
  /* background-color: blue; */
}

table td {
  width: calc(100% / 3);
  height: 80px;
  padding: 10px 10px;
  text-align: center;
  font-size: 12pt;
}
</style>
