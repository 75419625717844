<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <h2>低污染車輛補助</h2>
  <br />
  <p style="font-size: 14pt; font-weight: bold">◎桃園市低污染車輛補助:</p>
  <br />
  <ul style="padding: 0 10px">
    <li>
      1.(2023年)自112年2月起,微型電動二車無補助，普通輕型與普通重型有補助，相關補助辦法請至桃園市政府環境保護局查詢。
    </li>
    <br />
    <li>
      2.(2018年)自107年起:車主戶籍及舊燃油車車籍皆需設籍桃園市滿一年才可申請補助。
    </li>
    <br />
    <li>
      3.(2017年)自106年起以全額購車,桃園市環保局與工業局補助款以電滙滙入個人帳戶。
    </li>
    <br />
  </ul>

  <p style="font-size: 14pt; font-weight: bold">
    ◎
    <a
      href="https://www.tydep.gov.tw/tydep/PeopleApply/Detail/1022?type=0"
      target="_blank"
      rel="noopener noreferrer"
      >桃園市政府環境保護局--->桃園市發展低碳綠色城市低碳運具設施補助實施計畫</a
    >
  </p>
  <br />
  <br />
  <p style="font-size: 14pt; font-weight: bold">
    ◎
    <a href="https://tydeposs.cc/" target="_blank" rel="noopener noreferrer"
      >桃園市環保局補助線上申辦審查系統</a
    >
  </p>
  <br />
</template>

<style lang="scss" scoped></style>
