<script>
export default {
  setup() {
    return {};
  },
};
</script>
<template>
  <div>
    <div class="e-moving-link">
      <a
        href="https://www.e-moving.com.tw/EZ-R"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/components/contentPages/brand/e-moving/images/e-moving.png"
          alt=""
        />
      </a>
    </div>
    <!--  -->
  </div>
  <div class="home-news">
    <hr class="style-two" />
    <div class="heading"><span>EZ-R( 2023/8/15上市 )</span></div>
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230714085834.jpg"
      alt="EZ-R"
    />
    <img
      src="	https://www.e-moving.com.tw/emwspublished/C0002/pc20230809101941.jpg"
      alt="EZ-R"
    />
    <img
      src="	https://www.e-moving.com.tw/emwspublished/C0002/pc20230801113858.jpg"
      alt="EZ-R"
    />
    <img
      src="	https://www.e-moving.com.tw/emwspublished/C0002/pc20230802170056.jpg"
      alt="EZ-R"
    />
    <!--width="560" height="315"  -->
    <iframe
      src="https://www.youtube.com/embed/NlNmzg4qBTk"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
    <!--  -->

    <hr class="style-two" />

    <!-- <hr class="style-two" />
    <div class="heading"><span>勁炫125</span></div>
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220803101640.jpg"
      alt="勁炫125"
    />
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220803095926.jpg"
      alt="勁炫125"
    />
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230116182948.jpg"
      alt="勁炫125"
    />
    <hr class="style-two" />
    <div class="heading"><span>EZ-1</span></div>
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220823135618.jpg"
      alt="EZ1-1"
    />
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220825161522.jpg"
      alt="EZ1-2"
    />
    <hr class="style-two" />
    <div class="heading"><span>shine</span></div>
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20221130163310.jpg"
      alt="shine"
    />
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20221130143228.jpg"
      alt="shine"
    />
    <hr class="style-two" />
    <div class="heading"><span>bobe</span></div>
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0006/pc20210422102529.jpg"
      alt="bobe"
    />
    <img
      src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220728112338.jpg"
      alt="bobe"
    /> -->
  </div>
</template>
<style lang="scss" scoped>
div.heading {
  display: flex;
  justify-content: center;
  transform: translateY(-5px);
}

div.heading span {
  font-size: 14pt;
  padding: 5px 10px 5px 10px;
  background-color: #57595a;
  border-radius: 0 0 5px 5px;
  color: #fcfcfc;
}
img {
  margin: 0;
  vertical-align: top;
}

.e-moving-link {
  display: flex;
  justify-content: center;
}
.e-moving-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 20pt;
  color: #57595a;
}
.e-moving-link img {
  width: 260px;
  height: 40px;
}
.home-news > iframe {
  margin-top: 10px;
  width: 100%;
  height: 48vw;
}
</style>
