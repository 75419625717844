<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="shop-box">
    <div class="shop-name">七間町</div>
    <div class="header-description"></div>
    <div class="shop-name-origin"></div>
    <div class="shop-photo-box"></div>
    <div class="final-comment">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="shop-point-map"></div>
    <div class="tail-note"></div>
    <hr class="style-two" />
    <div class="nearby-attractions">
      <div class="nearby-attractions-note">
        <div></div>
      </div>
      <div class="nearby-attractions-point-map"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
