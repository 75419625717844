<script>
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<template>
  <!-- ==============================中欄:這裏要顯示:內容的區域============================================== -->
  <div id="contentBox" class="my-flex-XY-center">
    <div id="contentPage">
      <router-view
        :toggleMenuShow="props.toggleMenuShow"
        :isMenuOpen="props.isMenuOpen"
      />
      <!-- 在此处动态显示内容 -->
      <!-- <script>loadPage(".contentPage", "/contentPages/homeNews.txt");</script> -->
      <!-- <script>loadPage(".contentPage", "/contentPages/homeNews.html");</script> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* =============================================== */
/* ========== 中欄:這裏要顯示:內容的區域 =========== */
/* =============================================== */
/* ===毎頁內容頁之共通設置(毎頁內容頁的共用窗口)=== */
#contentBox {
  padding-top: 42px;
  width: 100%;
  /* position: relative; */
  /* padding: 75px 10px 10px 10px; */
  /* margin-top: 75px; */
  /* display: flex;
    justify-content: center; */
  /* 水平置中 */
  /* align-items: center; */
  /* 垂直置中 */

  > #contentPage {
    /*====使用 grid-area排版,  先命名"contentPage"區塊( class="contentPage")====*/
    /* grid-area: contentPage; */

    /* ============================================================================== */
    /* ========== @media screen and (max-width:700px) {display: grid; =============== */
    /*========================= 左側15VW  中間70VW 右側15VW ==========================*/
    /* ============ vw表示的是view width，也就是螢幕可是範圍寬度的百分比。 =============*/

    padding: 10px;
    /* display: flex;
    align-items: center;
    justify-self: center; */

    /* background-color: rgb(165, 165, 231); */
    /* margin: 1px; */
    // height: 600px;

    /* 顯示左側框線與背景色...===調校用=== */
    /* border: rgb(0, 17, 255) solid 1px; */
    /* background-color: #5d48aa; */
    width: 70vw;
    @media screen and (max-width: 920px) {
      width: 100%;
    }
  }
}
</style>
