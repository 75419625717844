<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="mode-box">
    <div class="top-car-img-box">
      <!-- 車種圖 -->
      <img
        src="@/components/contentPages/brand/e-moving/images/shine.png"
        alt="shine"
      />

      <!-- 車種 -->
      <hr class="style-two" />
      <div class="heading"><span>EM-25 : shine</span></div>

      <!-- 頭:說明:等級 -->
      <div class="header-description">
        微型電動二輪車 : 免駕照、家充充電110V、時速25km/hr以下。
      </div>
    </div>
    <hr class="style-two" />
    <div class="emoving-com-img">
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20221130163310.jpg"
        alt="shine"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20221130143228.jpg"
        alt="shine"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20221130163432.jpg"
        alt="shine"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20210423084610.jpg"
        alt="shine"
      />
    </div>
    <!--  -->
    <hr class="style-two" />
    <div class="e-moving-link">
      <a
        href="https://www.e-moving.com.tw/Shine"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/components/contentPages/brand/e-moving/images/e-moving.png"
          alt=""
        />
      </a>
    </div>
    <!--  -->
  </div>
</template>

<style lang="scss" scoped></style>
