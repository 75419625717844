<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";
import { onMounted } from "vue";
import iframeAutoSize from "@/components/contentPages/utils/iframeAutoSize.js";
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    // 在 onMounted 钩子中调用 iframeAutoSize 来自动调整 iframe 大小
    onMounted(() => {
      iframeAutoSize(); // iframe 的引用
      // iframeAutoSize(this.$refs.myIframe); // 传入 iframe 的引用
      //接下來在 iframe 調用 ,例:
      //  <div>
      //   <iframe ref="myIframe" src="your-iframe-source-url"></iframe>
      // </div>
    });

    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <!-- 注意:id=路徑_檔名 (這是inner-menu.js 與主程式 要套用)-->
        <li>
          <a href="/tworou-of-class/classification.html">二輪分類</a>
        </li>
        <li>
          <a href="/tworou-of-class/regulations.html">相關法規</a>
        </li>
        <li class="active">
          <a href="/tworou-of-class/safe-to-use.html">防衛駕駛</a>
        </li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- 注意:id=路徑_檔名(套用 href="xxx") -->
      <div id="classification" class="tab-inner">
        <!-- ============================================== -->
        <h3>※ 行車安全注意事項 ※</h3>

        <div class="my-PD-T20-D10">
          1.為了自身安全請配帶安全帽。 <br />
          2.準備騎乘時:請先左手抓緊煞車(常緊),坐好座位後,右手才開起電門(啟動)。
          <div class="my-img-WH100 my-PD-text-L20-D10">
            <img
              src="@/components/contentPages/twoRouOfClass/images/safeToUse/brakes.png"
              width="50px"
              height="50px"
              alt="左手抓緊煞車"
            />
          </div>
          3.確認側支撐架收回了没,確認收回後,左手釋放煞車再起步。 <br />
          4.停車時:左手抓緊煞車後(常緊),右手關閉電門(熄火)後,才可下車。
          <div class="my-img-WH100 my-PD-text-L20-D10">
            <img
              src="@/components/contentPages/twoRouOfClass/images/safeToUse/brakes.png"
              width="50px"
              height="50px"
              alt="左手抓緊煞車"
            />
          </div>
          <div>
            注意:以上2~4項是一般人常會忽略的危險,有可能小朋友或自身的不小心,誤觸油門而摔得四腳朝天。
          </div>
          5.行車時:左手指應在煞車把手上放置(預防動作),隨時做好煞車準備。 <br />
          (汽車也一樣,只要鬆開油門,右腳就需位移至煞車(預防動作，即:防衛駕駛),任何車種安全防衛駕駛觀念皆相同)
          <ul>
            注意:
            <li>
              左手如未在煞車把手上(預防動作),則在緊急時會發生延遲煞車作動時間。
              <ul>
                事件發生時:
                <li>->左手指位移至左煞把上(此時你的車子已位移?)</li>
                <li>->拉動煞車至煞車作動(此時你的車子已位移?)</li>
                <li>->煞車作動至煞車停車(此時你的車子已位移?)</li>
              </ul>
            </li>
          </ul>
          6.後視鏡是參考,有死角的,也要回頭確認身旁是否有車輛。 <br />
          7.遵守交通法規,確保平安。
        </div>
        <div class="my-PD-text-L20-D10">
          不管你騎的是什麼車,以上習慣要養成安全防衛駕駛觀,危害才不易發生。
          <br />
          安全駕駛,請觀看 相關網站 --> 安全駕駛宣導
        </div>

        <hr class="style-two" />

        <h3>※ 使用電動車應有的觀念 ※</h3>
        <div class="my-PD-T20-D10">
          <ul>
            ◎充電時機:
            <li>
              1.毎日行駛完畢,當日需充飽電以補足今日耗損的里程,以確保明日的里程。
              <ul>
                注意:
                <li>
                  ☉當日騎乘完畢,只要電量低於電錶80%或液晶掉兩格時,請記得把他充飽。
                </li>
                <li>☉偶爾1至3次騎到空電,再次充飽電對電池電容量是有益的。</li>
                <li>
                  ☉當騎乘至被控制器斷電後又没充飽電擱置,當低於某一電壓下,充電器將無法充電。
                </li>
              </ul>
            </li>

            <li>
              2.當日充飽電後,如一直未使用電車,則毎個月需再充飽電一次。
              <ul>
                注意:
                <li>
                  充電器插頭插入110v市電通電後,請勿超過12小時,以保護延長充電器電子元件之
                </li>
                <li>
                  壽命並達到節能的目的。(當然能7至8小時之內是最好,不管什麼電池都已充飽。)
                </li>
              </ul>
            </li>
          </ul>

          <ul>
            ◎電動車會因以下因素縮短里程:

            <li>
              電動車依各車種之不同,充飽電可行里程數亦不同,以原廠測試公佈之里程為主。
            </li>
            <li>
              [注意:微型電動自行車，原廠測試里程,(法規)以一人騎乘為主(有體重限制)。]
            </li>
            <li>1.夜晚開大燈行駛時。</li>
            <li>2.輪胎氣壓不足時。(這很重要,很重要,很重要)</li>
            <ul>
              <li>
                常見:因騎到輪胎没氣而不自知,小心會縮短里程並傷了電池與相關元件...
              </li>
              <li>
                例如:本可騎40公里卻剩20公里不到....所以輪胎氣壓檢查確保很重要...
              </li>
              <li>啊....你怎麼没感覺......</li>
              <li>1.牽車的時候沒感覺到比較重嗎?</li>
              <li>2.騎的時候不覺得動力有點下降了嗎?</li>
            </ul>
            <li>3.爬坡時。</li>
            <li>4.載重時。</li>
            <li>5.面向風阻時。</li>
            <li>6.使用高速檔大量放電時。</li>
            <li>7.急駛急停時。</li>
          </ul>
          <div>
            騎乘電動車必需消除以上問題才可能達到原廠公佈之里程。 <br />
            騎乘電動車必需瞭解該車的固定里程特性,以確保回程無虞。 <br />
            (不管什麼車,都會因上述因素而影響里程數,機車汽車即耗油,電動車即耗電。)
          </div>
          <ul>
            注意:
            <li>
              充電時間會因當日騎乘里程及電池與充電器的不同而造成充飽電的時間也會不同。
            </li>
          </ul>
        </div>
        <!-- ============================================ -->
      </div>
    </div>
  </div>
  <!-- //=============================== -->
</template>
<style lang="scss" scoped></style>
