/*===========================================================================================
     **主要用在google投影片:iframe**隨視窗即時調動時,依據iframe現狀寛度,照比例重新計算並自動調整iframe高度
     注意:
     1.在tab頁籤有使用iframe時要引用該程式時在該頁面下:
            <script src="/js/iframeAutoSize.js"></script>
            <!-- // 引入後再調用 iframeAutoSize() 函式 -->
             <script> iframeAutoSize();</script>
  ======================================start=============================================*/

/**
 * 監聽 iframe resize 事件，視窗大小改變時觸發。
 * @returns {void} 沒有回傳值
 * @example 
 * 使用該函式之頁面基本架構:
 * <div class="iframe-box">
 *       <iframe class="iframe">
 *       </iframe>
 *  </div>
 * 在需要的頁面中加入:
 * 引用:<script src="/js/iframeAutoSize.js"></script>
 * 即可啓用監聽 resize 事件。
 * @description 這個函式將監聽該頁面,若有視窗大小改變時,
 * 會取得該頁中#tab-box的寛度,以此寛度設定.iframe的寛度與寛度比例計算後的高度做設置。
 * (本程式會造成回流,需要改善問題:[Violation] 'load' handler took 209ms)
 * @see iframeAutoSize
 */
const iframeAutoSize=()=> {
    /*
      $(window).resize(function () {
          iframeReSize();
      });
   */

    iframeReSize();

    // 監聽 resize 事件，視窗大小改變時觸發。使用 debounce 避免事件觸發過於頻繁，減少瀏覽器的負擔和閃爍現象的發生。
    window.addEventListener("resize", debounce(iframeReSize, 200));



    // 使用 debounce 避免事件觸發過於頻繁，減少瀏覽器的負擔和閃爍現象的發生。
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }


    /**
     * iframe 的寛度與高度的設置
     * @returns {void} 沒有回傳值
     * @example 
     * 使用該函式之頁面基本架構:
     * <div class="iframe-box">
     *       <iframe class="iframe">
     *       </iframe>
     *  </div>
     *  @description 本函式為iframeAutoSize()內部函數。
     * @see iframeAutoSize()
     */
    function iframeReSize() {
        //取得內容頁的寛度
        var tabBox = document.getElementById("tab-box");
        // 取得 iframe 元素
        var iframe = document.querySelector(".iframe");

        if (iframe) {

            // alert("有 iframe ");
            // 取得 tab-box內容頁的寛度
            var width = tabBox.offsetWidth;
            if (width >= 960) {
                width = 960;
            }

            // 取得父級以上 iframe 的寬度為設定,
            // var width = document.querySelector(".tab-inner").offsetWidth;
            // 高度==依照tab-box內容頁寛度的比例計算
            var height = width / 1.65;
            // var height = Math.ceil(width / 1.63);
            // var height = Math.ceil(width / 1.7);
            // var height = Math.ceil(width / 2.3);
            // alert("iframe寛度:" + width);
            // alert("iframe 要設買的高度:" + height);
            iframe.style.width = width + "px";
            // 將計算好的高度設置給 iframe
            iframe.style.height = height + "px";
            return;
        } else {
            // alert("没有 iframe ");
            return;
        }


    }
}
//執行
iframeAutoSize();

export default iframeAutoSize;

/*==================================end===============================================
**主要用在iframe**隨視窗即時調動時,依據iframe現狀寛度,照比例重新計算並自動調整iframe高度
===========================================================================================*/
/*iframe 的說明:
  sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox 
          allow-downloads allow-modals"  frameborder="1"
1.sandbox：指定 iframe 的安全性限制。它是一個空格分隔的屬性值列表，可以包含以下值：
    allow-scripts：允許在 iframe 中執行 JavaScript 腳本。
    allow-popups：允許在 iframe 中彈出彈出視窗。
    allow-forms：允許在 iframe 中提交表單。
    allow-same-origin：允許 iframe 訪問具有相同原始來源的資源。
    allow-popups-to-escape-sandbox：允許在 iframe 彈出的彈出視窗逃離沙盒限制。
    allow-downloads：允許在 iframe 中下載資源。
    allow-modals：允許在 iframe 中顯示模態對話框。
2.frameborder：指定 iframe 的邊框寬度，設置為 1 表示顯示邊框。
這些屬性可用於限制 iframe 的操作和安全性，以確保載入的內容在受控的環境中運行。
根據您的需求，您可以在 <iframe> 元素中設置這些屬性來控制 iframe 的行為。
 */