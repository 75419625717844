<script>
import { onMounted } from "vue";

export default {
  setup() {
    const googleDaxiECar = () => {
      const textElement = document.getElementById("typing-text");
      const texts = ["大", "溪", "電", "動", "車"];
      let index = 0;

      const typeText = () => {
        if (index < texts.length) {
          const currentText = texts[index];
          textElement.textContent += currentText;
          index++;
        } else {
          setTimeout(() => {
            textElement.textContent = "";
            index = 0;
            typeText();
          }, 2000);
          return;
        }
        setTimeout(typeText, 500);
      };
      typeText();
    };

    onMounted(() => {
      //===執行函式
      googleDaxiECar();
    });

    return {
      // typingTextRef,
    };
  },
};
</script>

<template>
  <!-- ==============================頁尾:這裏要顯示:頁腳的區域============================================== -->
  <footer id="footerPage" class="mobile-768">
    <div id="footer_main">
      <div id="footer_copyright" class="mobile-920">
        <div>© 2014 啓鉅車業:電動車專營店</div>

        <div>地址:33561 桃園市大溪區仁和東街121號</div>
        <div>電話:03-3803701</div>
      </div>
      <div id="footer_browser">
        瀏覽本網站最佳瀏覽器:<a
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome</a
        >
      </div>
    </div>
    <div id="footerImgBox">
      <div id="facebook">
        <a
          href="https://www.facebook.com/profile.php?id=100057148281788"
          target="_blank"
        >
          <img
            src="@/assets/images/pages/facebook-logo_100.webp"
            alt="Facebook"
        /></a>
      </div>
      <div id="searchMe">
        <div class="typing-text-box">
          <div class="googleText">
            <span style="color: #3498db">G</span
            ><span style="color: #e74c3c">o</span
            ><span style="color: #f1c40f">o</span
            ><span style="color: #3498db">g</span
            ><span style="color: #27ae60">l</span
            ><span style="color: #e74c3c">e</span>
          </div>
          <div id="typing-text"></div>
          <div class="searchImgBox">
            <img src="@/assets/images/mobile/search__opsz48.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
/* =============================================== */
/* ========== 尾欄:for footer.css =========== */
/* =============================================== */
/* ==============================頁尾:這裏要顯示:頁腳的區域============================================== */
#footerPage {
  /*====使用 grid-area排版,  先命名headerPage區塊( class="headerPage")====*/
  /* grid-area: footerPage; */
  display: flex;
  justify-content: center;
  text-align: center;
  /* display: block; */
  padding: 10px;
  font-size: 12pt;
  /* -------------與頭頁NAV容器:背景色相同---------------- */
  background: rgb(239, 241, 237);
  /* 與頭頁導航欄容器下框劃一條黑線色相同(這裏上框劃線) */
  /* border-top: 1px #333333 solid; */
  /* 內容菜單下框與頭頁導航欄容器下框之陰影相同,,頁尾為向上偏移 */
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2);
  /* 內部所有元素間隔 */
  gap: 10px;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 10px 0;
  }
  > #footer_main {
    > #footer_copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 920px) {
        display: flex;
        flex-direction: column;
      }
      > div {
        margin: 0 5px;
        /* padding: 0 5px; */
      }
    }
  }

  > #footerImgBox {
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 42px;
    > #facebook {
      max-width: 40px;
      max-height: 40px;
      > a {
        max-width: 40px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          max-width: 40px;
          max-height: 40px;
          border-radius: 5px;
          /* border: 1px solid #fff; */
        }
      }
    }
    > #searchMe {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: #fff; */
      /* ===google 大溪電動車  搜寻==== */
      max-width: 300px;
      max-height: 40px;
      > .typing-text-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        padding: 2px;
        width: 210px;
        > .googleText {
          font-size: 14pt;
          /* font-weight: bold; */
          padding: 2px;
        }
        > #typing-text {
          width: 120px;
          background-color: #fcfcfc;
          font-size: 14pt;
          /* font-weight: bold; */
          padding: 2px 5px 2px 5px;
          border: 1px solid #9d9d9d;
          border-radius: 10px;
          text-align: left;
        }
        > .searchImgBox {
          > img {
            width: 30px;
            height: 30px;
            /* border: 1px solid #9d9d9d; */
          }
        }
      }
    }
  }
}
</style>
