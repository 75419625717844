<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="mode-box">
    <div class="top-car-img-box">
      <!-- 車種圖 -->
      <img
        src="@/components/contentPages/brand/e-moving/images/ez-1.png"
        alt="EZ-1"
      />

      <!-- 車種 -->
      <hr class="style-two" />
      <div class="heading"><span>EZ-1</span></div>

      <!-- 頭:說明:等級 -->
      <div class="header-description">
        輕型 : 單電池、gogoro電池交換系統、電池月租。
      </div>
    </div>
    <hr class="style-two" />
    <div class="emoving-com-img">
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230522111351.jpg"
        alt="EZ-1"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230522111414.jpg"
        alt="EZ-1"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0001/pc20230522111435.jpg"
        alt="EZ-1"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230522111459.jpg"
        alt="EZ-1"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0006/pc20230522111515.jpg"
        alt="EZ-1"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0013/0_20230503091315.jpg"
        alt="EZ-1"
      />
    </div>
    <!--  -->
    <hr class="style-two" />
    <div class="e-moving-link">
      <a
        href="https://www.e-moving.com.tw/EZ1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/components/contentPages/brand/e-moving/images/e-moving.png"
          alt=""
        />
      </a>
    </div>
    <!--  -->
  </div>
</template>

<style lang="scss" scoped></style>
