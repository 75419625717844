<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";
import { onMounted } from "vue";
import iframeAutoSize from "@/components/contentPages/utils/iframeAutoSize.js";
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    // 在 onMounted 钩子中调用 iframeAutoSize 来自动调整 iframe 大小
    onMounted(() => {
      iframeAutoSize(); // iframe 的引用
      // iframeAutoSize(this.$refs.myIframe); // 传入 iframe 的引用
      //接下來在 iframe 調用 ,例:
      //  <div>
      //   <iframe ref="myIframe" src="your-iframe-source-url"></iframe>
      // </div>
    });

    return { props };
  },
};
</script>
<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <!-- 注意:id=路徑_檔名 (這是inner-menu.js 與主程式 要套用)-->
        <li>
          <a href="/tworou-of-class/classification.html">二輪分類</a>
        </li>
        <li class="active">
          <a href="/tworou-of-class/regulations.html">相關法規</a>
        </li>
        <li>
          <a href="/tworou-of-class/safe-to-use.html">防衛駕駛</a>
        </li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- 注意:id=路徑_檔名(套用 href="xxx") -->
      <div id="classification" class="tab-inner">
        <!-- ========================================================================== -->
        <div class="my-PD-T20-D10">
          <span style="text-align: left; font-weight: bold; font-size: 14pt"
            >一、<a
              style="text-decoration: underline"
              href="https://law.moj.gov.tw/LawClass/LawAll.aspx?pcode=K0040068&kw=%e5%be%ae%e5%9e%8b%e9%9b%bb%e5%8b%95%e4%ba%8c%e8%bc%aa%e8%bb%8a"
              target="_blank"
            >
              輔助自行車及微型電動二輪車型式安全審驗管理辦法。</a
            > </span
          ><br />
          <span style="text-align: left; font-weight: bold; font-size: 14pt"
            >二、<a
              style="text-decoration: underline"
              href="https://law.moj.gov.tw/LawClass/LawParaDeatil.aspx?pcode=K0040012&bp=3"
              target="_blank"
              >道路交通管理處罰條例</a
            >(詳細請點擊連結。)以下為抽出條例:</span
          >
          <br />
          <ul>
            第 三 章 慢車:<br />
            <li>
              第 76 條:
              <ul>
                1.慢車駕駛人，載運客、貨有下列情形之一者，處新臺幣三百元以上六百元以下罰鍰：<br />
                <li></li>
                <li>一、乘坐人數超過規定數額。</li>
                <li>二、裝載貨物超過規定重量或超出車身一定限制。</li>
                <li>
                  三、裝載容易滲漏、飛散、有惡臭氣味及危險性貨物不嚴密封固或不為適當之裝置。
                </li>
                <li>四、裝載禽、畜重疊或倒置。</li>
                <li>五、裝載貨物不捆紮結實。</li>
                <li>六、上、下乘客或裝卸貨物不緊靠路邊妨礙交通。</li>
                <li>七、牽引其他車輛或攀附車輛隨行。</li>
              </ul>
              <ul>
                2.腳踏自行車及電動輔助自行車駕駛人附載幼童有下列情形之一者，處新臺幣三百元以上六百元以下罰鍰：
                <li>一、駕駛人未滿十八歲。</li>
                <li>二、附載之幼童年齡或體重超過規定。</li>
                <li>
                  三、不依規定使用合格之兒童座椅、腳踏自行車或電動輔助自行車。
                </li>
                <li>四、未依規定附載幼童。</li>

                3.前項附載幼童之腳踏自行車、電動輔助自行車應遵行事項及兒童座椅之檢驗方式，由交通部定之。
              </ul>

              第 71-1 條:
              <ul>
                1.
                微型電動二輪車有下列情形之一者，處所有人新臺幣一千二百元以上三千六百元以下罰鍰，並禁止其行駛：
                <li>一、未依規定領用牌照行駛。</li>
                <li>二、使用偽造或變造之牌照。</li>
                <li>三、牌照借供他車使用或使用他車牌照。</li>
                <li>四、已領有牌照而未懸掛或不依指定位置懸掛。</li>
                <li>五、牌照業經註銷，無牌照仍行駛。</li>
                <li>
                  六、牌照遺失不報請該管主管機關補發，經舉發後仍不辦理而行駛。
                </li>

                2.前項微型電動二輪車屬經型式審驗合格車輛者，當場移置保管；前項微型電動二輪車屬未經型式審驗合格車輛者，沒入之；第二款、第三款之牌照扣繳之。<br />
                3.微型電動二輪車未領用有效牌照、懸掛他車牌照或未懸掛牌照於道路停車者，依前二項規定處罰，並當場移置保管。<br />
                4.本條例中華民國一百十一年四月十九日修正施行前，已經檢測及型式審驗合格，並黏貼審驗合格標章之微型電動二輪車，應於本條例一百十一年四月十九日修正施行後二年內依規定登記、領用、懸掛牌照。逾期未領用者，依第一項第一款處罰之。
              </ul>
            </li>
          </ul>
          三、增訂並修正道路交通管理處罰條例條文(詳細請點擊連結。)以下為抽出條例:
          <ul>
            第 72-2 條:
            <li>
              未滿十四歲之人，駕駛微型電動二輪車或個人行動器具者，處新臺幣六百元以上一千二百元以下罰鍰，並當場禁止其駕駛，車輛移置保管。
            </li>
            <li>
              微型電動二輪車或個人行動器具租賃業者，未於租借微型電動二輪車或個人行動器具予駕駛人前，教導駕駛人車輛操作方法及道路行駛規定者，處新臺幣六百元以上一千二百元以下罰鍰。
            </li>
          </ul>
        </div>
        <!-- ============================================ -->
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
