<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <hr class="style-two" />
  <div class="heading"><span>桃園公家機關</span></div>
  <table>
    <tr>
      <td>
        <a href="https://www.tycg.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/TaoyuanCityGovernment.avif"
            alt="桃園市政府"
        /></a>
      </td>
      <td>
        <a href="https://www.tydep.gov.tw/tydep/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/TaoyuanCityGEPB.avif"
            alt="桃園市政府環境保護局"
        /></a>
      </td>
      <td>
        <a href="https://www.tycg.gov.tw/daxi/index.jsp" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/DaxiDistrictOffice.avif"
            alt="桃園市大溪區公所"
        /></a>
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://www.tyemid.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/TyemidGovTw.avif"
            alt="桃園市政府環境清潔稽查大隊"
        /></a>
      </td>

      <td></td>
      <td></td>
    </tr>
  </table>
  <hr class="style-two" />
  <div class="heading"><span>院部級公家機關</span></div>
  <table>
    <tr>
      <td>
        <a href="https://mobile.epa.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/EpaGovTw.avif"
            alt="行政院環保署"
        /></a>
      </td>
      <td>
        <a href="https://www.thb.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/ThbGovTw.svg"
            alt="中華民國交通部公路總局"
        /></a>
      </td>
      <td>
        <a href="https://www.itri.org.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/ItriOrgTw.svg"
            alt="工業技術研究院"
        /></a>
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://www.moeaboe.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/MoeaboeGovTw.avif"
            alt="經濟部能源局"
        /></a>
      </td>

      <td>
        <a href="https://168.motc.gov.tw/" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/168MotcGovTw.svg"
            alt="交通安全入口網"
        /></a>
      </td>
      <td>
        <a href="https://www.lev.org.tw/index" target="_blank"
          ><img
            src="@/components/contentPages/government-body/images/LevOrgTw.avif"
            alt="經濟部電動機車產業網"
        /></a>
      </td>
    </tr>
  </table>
  <hr class="style-two" />
  <div class="heading"><span>其它</span></div>
  <table>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </table>
</template>

<style lang="scss" scoped>
div.heading {
  display: flex;
  justify-content: center;
  transform: translateY(-5px);
}

div.heading span {
  font-size: 14pt;
  padding: 5px 10px 5px 10px;
  background-color: rgb(87, 89, 90);
  border-radius: 0 0 5px 5px;
  color: #fcfcfc;
}

table {
  width: 100%;
  /* background-color: blue; */
}

table td {
  width: calc(100% / 3);
  /* height: 50px; */
  padding: 5px 5px;
  /* text-align: center; */
  font-size: 14pt;
}

td a img {
  max-width: 200px;
  max-height: 70px;
}

td a {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
