<script>
import { onMounted } from "vue";
import iframeAutoSize from "@/components/contentPages/utils/iframeAutoSize.js";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    // 在 onMounted 钩子中调用 iframeAutoSize 来自动调整 iframe 大小
    onMounted(() => {
      iframeAutoSize(); // 调用 iframeAutoSize 函数
    });

    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <!-- 注意:id=路徑_檔名 (這是inner-menu.js 與主程式 要套用)-->
        <li class="active">
          <a href="motor-test/written-test.html">筆試</a>
        </li>
        <li><a href="/motor-test/road-test.html">路考</a></li>
        <!-- <li><a href="#businessHours">營業時間</a></li>-->
      </ul>
    </div>
    <div id="tab-box">
      <!-- ===================================== -->
      <h2>2017機車筆試題庫</h2>
      <h3>
        依據現行道路交通安全規則第60條，考領輕型或普通重型機車駕駛執照須年滿18歲(成年)。
      </h3>
      <hr class="style-two" />

      <div class="centerBox">
        <div class="centerText">
          <h3>
            <a
              href="https://docs.google.com/document/d/15RCNOS8jWmWa8kJwbEQVQee3Ekoa3umZ9_nB3_sa5II/preview?authuser=0"
              target="_blank"
              rel="機車法規選擇題-中文.odt"
            >
              (1)法規: 選擇題</a
            >
          </h3>
        </div>
        <div class="centerText">
          <h3>
            <a
              href="https://docs.google.com/document/d/18k4G7IN4JpHIHUk-c53Jz-n80pi2PDEhhNFSaPEAyjU//preview?authuser=0"
              target="_blank"
              rel="機車法規是非題-中文.odt"
              >(1)法規: 是非題</a
            >
          </h3>
        </div>
        <div class="centerText">
          <h3>
            <a
              href="https://drive.google.com/file/d/0B4feQ50S6oMIYmQ3NElpRDVPaVk/view?usp=share_link&resourcekey=0-ZsBX1FLfQM_-pRs4mrESxA"
              target="_blank"
              rel="交通標誌.jpg"
              >標誌,標線,號誌</a
            >
          </h3>
        </div>
        <div class="centerText">
          <h3>
            <a
              href="https://docs.google.com/document/d/1VhthJ4iwe9Q5fTEIqHanU65Z5qMuxHlShNdNmetCe9o//preview?authuser=0"
              target="_blank"
              rel="交通標誌.jpg"
              >(2)標誌,標線,號誌:選擇題</a
            >
          </h3>
        </div>
        <div class="centerText">
          <h3>
            <a
              href="https://docs.google.com/document/d/1r3ymuLvemHYZ-09BKhqnEyNlTa2-35rl39nN9qq0pKs//preview?authuser=0"
              target="_blank"
              rel="交通標誌.jpg"
              >(2)標誌,標線,號誌:是非題</a
            >
          </h3>
        </div>
      </div>
      <div class="centerText">
        <h3>
          <a
            href="https://www.mvdis.gov.tw/m3-simulator-drv/"
            target="_blank"
            rel="機車線上隨機模擬考系統"
          >
            交通部公路總局汽機車線上隨機模擬考系統</a
          >
        </h3>
      </div>
      <div class="sourceTo">== 資料來源:交通部公路總局與監理單位 ==</div>
      <!-- ==================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.centerBox {
  gap: 15px;
}

.centerText {
  /* width: calc(100% / 1); */
  /* height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 20px 5px 20px;
  border-radius: 50px;
}

.centerText:hover {
  background-color: yellow;
  border: 2px solid #d7313199;
}

.centerText a {
  color: blue;
  font-size: 16pt;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.sourceTo {
  width: 100%;
  text-align: end;
}
</style>
