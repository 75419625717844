// ========================  gourmetFood.html用    以下為圖片放大縮小之控制 ========================


/**
 *  pagesDynamicClick--為內部網頁放大縮小之控制
 * @description 
 * 主要目的:
 * 將所有飲食網頁於選擇時,讓程式判斷被點擊的網頁該放大或縮小。
 *  @example 
 * 使用方式:
 * 在需要的網頁(例如:gourmetFood.html)引入外部檔案如下:
 * <script src="/js/pagesDynamicClick.js"></script>
 * (引入即執行)
 */
const pagesDynamicClick=()=> {
    const childElements = document.querySelectorAll('.child');
    // if (childElements.length == 0) {//如果抓不到就再抓
    //     pagesDynamicClick();
    //     return;
    // }
    let isExpanded = false;
    // alert(childElements);
    // console.log(childElements);
    childElements.forEach(child => {
        child.addEventListener('click', () => {
            // alert("我有點擊");
            if (isExpanded) {//true 已開啟(放大狀態),要將縮小
                //1.設回預訂的項目
                child.style.maxWidth = '200px';
                child.style.maxHeight = '200px';
                // child.style.transform = 'scale(1.1)';
                child.style.position = 'static';
                child.style['z-index'] = '0';
                child.classList.remove("toMaxBox");//縮小該區塊後在.child 刪除.toMaxBox
                //2.開啟顯示全部項目
                childElements.forEach(child => {
                    // 如果使用 display: none;，該元素所佔的空間不會存在於頁面上。
                    // 如果你希望元素不顯示但仍要佔用空間，可以使用 visibility: hidden;，這樣該元素不會顯示，但會在頁面上保留其佔用的空間。
                    child.style.display = 'flex';
                });
            } else {//false  已關閉(縮小狀態),要將放大
                // 1.先全部關閉顯示
                childElements.forEach(child => {
                    // 如果使用 display: none;，該元素所佔的空間不會存在於頁面上。
                    // 如果你希望元素不顯示但仍要佔用空間，可以使用 visibility: hidden;，這樣該元素不會顯示，但會在頁面上保留其佔用的空間。
                    child.style.display = 'none';
                });
                //2.設定放大被點擊的項目
                const height = child.scrollHeight;
                child.style.maxWidth = '100%';
                child.style.maxHeight = '100%';
                // child.style.maxHeight = `${height}px`;
                // child.style.transform = 'scale(1)';
                // child.style.position = 'absolute';
                child.style['z-index'] = '1';
                child.classList.add("toMaxBox");//放大該區塊後在.child增加1個類.toMaxBox
                //3.顯示放大後的項目
                child.style.display = 'flex';
            }
            isExpanded = !isExpanded;
        });
    });
}

setTimeout(function () {
    pagesDynamicClick();
}, 0);
// pagesDynamicClick();
export default pagesDynamicClick;
