<script>
import { ref } from "vue";
// import Head from "@/components/layouts/Head.vue"; //组件 路徑
import Header from "@/views/layouts/Header.vue";
import Content from "@/views/layouts/Content.vue";
import Footer from "@/views/layouts/Footer.vue";

export default {
  components: {
    // Head, //注册 Head 组件
    Header,
    Content,
    Footer,
  },

  setup() {
    // ===================這是行動菜單(小圖)====================
    const isMenuOpen = ref(false);
    const toggleMenuShow = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };
    return { toggleMenuShow, isMenuOpen };
  },
};
</script>

<!-- 這是HTML  -->
<template>
  <!-- <Head />引入 Head 组件 -->
  <Header :isMenuOpen="isMenuOpen" :toggleMenuShow="toggleMenuShow" />
  <Content :isMenuOpen="isMenuOpen" :toggleMenuShow="toggleMenuShow" />
  <Footer />
</template>

<!-- 這是SCSS  -->
<style lang="scss">
// <style lang="scss" scoped>
/* 手機的另寫,分開會有問題(PC切換手機無法正常成功) */
/* @import "/css/media.css"; */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Microsoft JhengHei", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* font-family: 'Noto Sans TC', sans-serif; */
  /* background-color: #fcfcfc; */
  /* background-color: #f0f0f0; */
}
/* ================================ */
/* =======     全體的設置     ====== */
/* ================================ */
*,
*::after,
*::before {
  /* ==* 將所有預設歸零 ==*/
  /*==== margin屬性為給定元素設置所有四個（上下左右）方向的外邊距屬性(外框)。 ====*/
  margin: 0;
  /*====border CSS 簡寫屬性控制元素所有四條邊的中邊距區域(中框)====*/
  border: 0;
  /*====padding CSS 簡寫屬性控制元素所有四條邊的內邊距區域(內框)====*/
  padding: 0;
  /* list-style CSS 屬性是一個簡寫對屬性集合，包括list-style-type, list-style-image, 和list-style-position。 */
  list-style: none;
  /* 取消超連結底線 */
  text-decoration: none;
  /* 設定:統一使用字體 */

  /* box-sizing 的作用是控制 width 與 height 作用的對象空間，換另一個說法，則是設定物件尺寸的計算方式 */
  /*(寬高設定作用在content內容範圍-預設)*/
  /* box-sizing: content-box; */
  /* （寬高設定作用在border邊框外緣的範圍內）border(*2)加padding(*2)加content==總高(或總寛) */
  box-sizing: border-box;
}

/* ====全體設置:h1字體標題->全部置中===== */
h1,
h2,
h3,
h4 {
  text-align: center;
  /* margin: 10px 0 5px 0; */
  padding: 10px 0 5px 0;
}

/* ====全體設置:讓全體圖片維持等比例縮放 ====*/
img {
  width: 100%;
  height: 100%;
}

/* CSS 中的一個選擇器，它可以用來設定全域的 CSS 變數。 
例:
body {
    color: var(--blue);
    font-size: var(--font-family-sans-serif);
}
*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #868e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

/* ============================================================ */
/* ========================= 自定類 ========================== */
/* ============================================================ */
.my-flex-XY-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-img-hover:hover {
  /* transform: translate(0, -10px); */
  /*
    @5px：水平偏移量，表示陰影相對於元素向右偏移 5 個像素。
    @5px：垂直偏移量，表示陰影相對於元素向下偏移 5 個像素。
    @5px：模糊度，表示陰影模糊程度為 5 像素。
    @6px：擴散程度，表示陰影擴散到元素外部 6 像素。
    @#cccccc：陰影的顏色，這裡使用的是 #cccccc，表示淺灰色。
    */
  box-shadow: 0px 0px 5px 6px #cccccc;
}

.my-PD-T20-D10 {
  padding: 20px 0 10px 0;
}

.my-PD-text-L20-D10 {
  padding: 0px 0 10px 20px;
}

.my-img-WH100 {
  width: 100px;
  height: 100px;
}

div.my-PD-T20-D10 > div,
div.my-PD-T20-D10 ul,
div.my-PD-T20-D10 ul li {
  margin-left: 10px;
  padding-left: 10px;
}

/* ============================================================ */
/* =========================元素背景========================== */
/* ============================================================ */
.my-background-one {
  background-image: linear-gradient(
    to right,
    rgba(63, 63, 63, 0),
    rgba(63, 63, 63, 0.2),
    rgba(63, 63, 63, 0.75),
    rgba(63, 63, 63, 0.2),
    rgba(63, 63, 63, 0)
  );
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); */
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)); */
}

.my-background-tow {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

/* ============================================================ */
/* =========================水平分隔線========================== */
/* ============================================================ */
/* 漸變 color1 - color2 - color1 */
hr {
  margin: 5px 0;
  width: 100%;
}

hr.style-one {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

/* 透明漸變 - color - transparent */

hr.style-two {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

/* 雙線 */

hr.style-three {
  border: 0;
  border-bottom: 1px dashed #ccc;
  background: #999;
}

/* 單線陰影 */

hr.style-four {
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
}

/* 雲朵状 */

hr.style-five {
  border: 0;
  height: 0;
  /* Firefox... */
  box-shadow: 0 0 10px 1px black;
}

hr.style-five:after {
  /* Not really supposed to work, but does */
  content: "\00a0";
  /* Prevent margin collapse */
}

/* 内嵌 */
hr.style-six {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* 暈状 */
hr.style-seven {
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}

hr.style-seven:before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}

/* 文字插入式 */
hr.style-eight {
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}

hr.style-eight:after {
  content: "請輸入文字";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: white;
}

/* ============================================================== */
/* =========== Google Fonts https: //fonts.google.com =========== */
/* ===========    Google 推出 5 款免費的中文網頁字型    =========== */
/* ============================================================== */
/* ----- 1.cwTeXKai (Chinese Traditional) 楷體字體 ----- */
@import url(https://fonts.googleapis.com/earlyaccess/cwtexkai.css);
/* CSS使用方式-> font-family: "cwTeXKai",serif; */

/* ----- 2.cwTeXYen (Chinese Traditional) 圓體字體  ----- */
@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css);
/* CSS使用方式-> font-family: "cwTeXYen",sans-serif; */

/* ----- 3.cwTeXFangSong (Chinese Traditional) 仿宋體字體 ----- */
@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css);
/* CSS使用方式-> font-family: "cwTeXFangSong",serif; */

/* ----- 4.Noto Sans TC (Chinese Traditional) 黑體字體（思源黑體） ----- */
@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
/* CSS使用方式-> font-family:"Noto Sans TC",sans-serif; */

/* ----- 5.cwTeXMing (Chinese Traditional) 明體字體 ----- */
@import url(https://fonts.googleapis.com/earlyaccess/cwtexming.css);
/* CSS使用方式-> font-family: "cwTeXMing",serif; */

/* ============================================================*/
/* ========**************  手機模式 切換  **************========*/
/* ============================================================*/
@media screen and (max-width: 920px) {
  /*
    ==============================
       ===  手機版-菜單圖顯示 ====
    ==============================
    */
  /* 這是整個區塊,區塊中有#mobileMenuClose 與 #mobileMenuOpen 兩個小圖都會顯示 */
  // .mobile-menu {
  //   /* 注意:這裏如使用 display:block 則會造成，header.css中.mobile-men所設display: flex之置中靠右將無法作動 */
  //   /* display: block; */
  //   display: flex;
  // }

  /* 以下標註起來的是因為手機無法作動(PC版OK)--start */
  /* ----先關閉#mobileMenuClose的小圖---- */
  /* #mobileMenuClose { */
  /* display: none; */
  /* } */

  /* --再顯示#mobileMenuOpen的小圖 */
  /* #mobileMenuOpen { */
  /* display: block; */
  /* } */

  /* 以上標註起來的是因為手機無法作動(PC版OK)--end */
  /* ===================
--行動MENU排版-開啓 
=====================*/
  // .menu-item {
  //   /* 改垂直排列 */
  //   flex-direction: column;
  //   /*隱藏選單開始*/
  //   max-height: 0;
  //   overflow: hidden;
  //   /*隱藏選單end*/
  //   position: absolute;
  //   /*追隨所處容器之上層容器的相對位置。*/
  //   /*讓選單從左到右延伸start*/
  //   right: 0;
  //   left: 0;
  //   /*讓選單從左到右延伸end*/

  //   /*把menu-item位置移到.headerPage下方。*/
  //   /* z-index: 100; */
  //   transition: all 1.5s;
  //   height: 0;
  //   opacity: 0;
  // }

  /* 行動MENU排版-li的樣式 */
  // .menu-item li {
  //   float: none;
  //   border-bottom: 1px solid rgb(153, 153, 153);
  //   background: rgb(239, 241, 237);
  //   width: 100%;
  // }

  // /* 行動MENU排版-a的樣式 */
  // .main-menu-a {
  //   /* 寛度撐到最開,以方便行動版點擊 */
  //   /* width: 100%; */
  //   text-align: center;
  // }

  /* ===================================================== */
  /* ========jQ點擊後動態在 body 加上 menu-show時作動======= */
  /* ===================================================== */
  /* --------------------------------- 
 ====切換行動菜單的小圖(點擊作動時)==== 
----------------------------------*/
  /* 以下標註起來的是因為手機無法作動(PC版OK)--start */
  /* 打開#mobileMenuClose小圖 */
  /* body.menu-show #mobileMenuClose { */
  /* display: block; */
  /* } */

  /* 關閉#mobileMenuOpen小圖 */
  /* body.menu-show #mobileMenuOpen { */
  /* display: none; */
  /* } */

  /* 以上標註起來的是因為手機無法作動(PC版OK)--end */
  /* ------------------------------------------------------ */
  /*當點下行動MENU後body.menu-show時將內容頁tab頁籤的選單隱藏*/
  // .main-nav-menu.menu-show .tab-title.mobile-920 {
  //   display: none;
  // }

  /*當點下行動MENU後body.menu-show時將主菜單顯示*/
  // .main-nav-menu.menu-show .menu-item {
  //   /* 指定開啓後的最大高度 */
  //   max-height: 500px;
  //   /* 選單開啟後,選單頂部所處位置 */
  //   top: 40px;
  //   height: auto;
  //   /* 設置透明度:0完全透明~1完全不透明 */
  //   opacity: 0.98;
  // }

  /* ------------------------------------------------------ */
  #contentBox,
  #contentPage {
    width: 100%;
    /* padding: 2px; */
    /*超連結:占滿整個區塊*/
    display: block;
    /* ===本div元素框置中=== */
    justify-self: center;
  }

  /* .main-nav { */
  /* width: 100%;
    background-color: rgb(211, 209, 201); */
  /*    flex-wrap: wrap;*/
  /* display: flex;  */
  /* justify-content: space-between; */
  /* float: right; */
  /* display: flex;  */
  /* ====靠右對齊==== */
  /* margin-left: auto; */
  /* margin-block-end: auto; */
  /* } */

  // #footer_copyright.mobile-920,
  .new-lionfree.mobile-920 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  // #footerPage.mobile-768,
  .course-box.mobile-768 > ul,
  .practice-img-box.mobile-768 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 10px 0;
  }
}
</style>
