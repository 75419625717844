<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";
import { onMounted } from "vue";
import iframeAutoSize from "@/components/contentPages/utils/iframeAutoSize.js";
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    // 在 onMounted 钩子中调用 iframeAutoSize 来自动调整 iframe 大小
    onMounted(() => {
      iframeAutoSize(); // iframe 的引用
      // iframeAutoSize(this.$refs.myIframe); // 传入 iframe 的引用
      //接下來在 iframe 調用 ,例:
      //  <div>
      //   <iframe ref="myIframe" src="your-iframe-source-url"></iframe>
      // </div>
    });

    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <!-- 注意:id=路徑_檔名 (這是inner-menu.js 與主程式 要套用)-->
        <li class="active">
          <a href="/tworou-of-class/classification.html">二輪分類</a>
        </li>
        <li>
          <a href="/tworou-of-class/regulations.html">相關法規</a>
        </li>
        <li>
          <a href="/tworou-of-class/safe-to-use.html">防衛駕駛</a>
        </li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- 注意:id=路徑_檔名(套用 href="xxx") -->
      <div id="classification" class="tab-inner">
        <div class="my-PD-T20-D10">
          <li>
            <strong>自2016年即105年度開始: </strong
            >輕機車要考筆試與路考,建議以<strong> 普通重型機車駕照 </strong
            >為基本駕照考取。
          </li>
          <li>
            <span style="text-align: left; font-weight: bold; font-size: 14pt"
              >道交法規（民國 110 年 09 月 23 日 ） :</span
            >
            (若法規有新修正，請以新道交法為主。)
          </li>
          <li>
            <span style="text-align: left; font-weight: bold; font-size: 13pt"
              >第 60 條 申請汽車駕駛執照考驗者，應具有下列資格：</span
            >
          </li>
          <ul>
            一、年齡：
            <li>
              （一）考領普通駕駛執照、輕型或普通重型機車駕駛執照須年滿十八歲，最高年齡不受限制。
            </li>
            <li>
              （二）考領大型重型機車駕駛執照須年滿二十歲，最高年齡不受限制。
            </li>
            <li>
              (應考大型重型機車駕駛執照者，須領有普通重型機車駕駛執照一年以上之經歷，並經立案之駕駛訓練機構駕駛訓練結業。
              )
            </li>
          </ul>
          <li>
            <span style="text-align: left; font-weight: bold; font-size: 13pt"
              >註:
              <a
                style="text-decoration: underline"
                href="https://www.thb.gov.tw/cp.aspx?n=169"
                target="_blank"
                >高齡駕駛人駕駛執照管理制度說明</a
              >(年滿75歲以上之高齡駕駛人)</span
            >
          </li>
        </div>
        <div class="iframe-box">
          <iframe
            class="iframe"
            scrolling="no"
            src="https://docs.google.com/presentation/d/e/2PACX-1vSTU6jIxAbRZxt47c6vzOR5Q1RA-p4r7QYzw5tUJ6Nle54Ha7qerHe7x2fvwgm09Y9hEPElV4SGsm20/embed?start=true&loop=true&delayms=1000"
            frameborder="0"
            width="960"
            height="569"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- //=============================== -->
</template>

<style lang="scss" scoped>
.iframe-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
