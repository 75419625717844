<script>
// 引入样式文件
import "@/components/contentPages/brand/carSharing.css";
//引入同廠牌之各型式車種
import Bobe from "@/components/contentPages/brand/e-moving/carmodel/Bobe.vue";
import Ez1 from "@/components/contentPages/brand/e-moving/carmodel/Ez1.vue";
import Ezr from "@/components/contentPages/brand/e-moving/carmodel/Ezr.vue";
import Ginsan125 from "@/components/contentPages/brand/e-moving/carmodel/Ginsan125.vue";
import Shine from "@/components/contentPages/brand/e-moving/carmodel/Shine.vue";
// 引入 pagesDynamicClick.js 文件:這是控制多組件(多頁面)之點擊放大縮小的javascript
import { onMounted } from "vue";
import pagesDynamicClick from "@/components/contentPages/utils/pagesDynamicClick.js";

export default {
  // methods: {
  //   handleDynamicClick() {
  //     // 调用 pagesDynamicClick 中的某个函数或方法
  //     pagesDynamicClick(); //  pagesDynamicClick()要调用的函数
  //     // <div>
  //     // <!-- 点击按钮触发动态点击事件 -->
  //     // <button @click="handleDynamicClick">动态点击</button>
  //     // </div>
  //   },
  // },
  // name: "MyPage",
  // metaInfo: {
  //   description: "這是頁面的描述",
  // },
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  components: {
    Bobe,
    Ez1,
    Ezr,
    Ginsan125,
    Shine,
  },
  setup(props) {
    const carModels = [
      { component: Ezr, isExpanded: false },
      { component: Ginsan125, isExpanded: false },
      { component: Ez1, isExpanded: false },
      { component: Shine, isExpanded: false },
      { component: Bobe, isExpanded: false },
    ];

    const toggleExpand = (index) => {
      // console.log("我進來了");

      carModels.forEach((model, i) => {
        // console.log(model, i);
        model.isExpanded = i === index && !model.isExpanded;
      });
    };
    // 在pagesDynamicClick組件掛載後執行點擊放大縮小的邏輯
    onMounted(() => {
      pagesDynamicClick();
    });

    return {
      carModels,
      toggleExpand,
      props,
    };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li class="active"><a href="/brand/e-moving.html">中 華</a></li>
        <li><a href="/brand/wasion.html">威 勝</a></li>
        <li><a href="/brand/sanno.html">山 王</a></li>
        <li><a href="/brand/nicerich.html">錡明</a></li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- ============================================== -->

      <div class="parent">
        <div class="child" @click="toggleExpand(0)">
          <div id="carmodel-1"><Ezr /></div>
        </div>
        <div class="child" @click="toggleExpand(1)">
          <div id="carmodel-2"><Ginsan125 /></div>
        </div>
        <div class="child" @click="toggleExpand(2)">
          <div id="carmodel-3"><Ez1 /></div>
        </div>
        <div class="child" @click="toggleExpand(3)">
          <div id="carmodel-4"><Shine /></div>
        </div>
        <div class="child" @click="toggleExpand(4)">
          <div id="carmodel-5"><Bobe /></div>
        </div>
      </div>
      <!-- ============================================== -->
    </div>
  </div>
</template>

<style lang="scss">
.parent {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;

  > .child {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;
    // transition: all 0.3s ease-in-out;
    background-color: #fcfcfc;
    padding: 10px;

    &:hover {
      transform: scale(1);
      cursor: pointer;
    }
  }
  > .child.toMaxBox:hover {
    transform: scale(1);
  }
}
// .parent {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 15px;
//   position: relative;
// }

// .child {
//   max-width: 200px;
//   max-height: 200px;
//   overflow: hidden;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   transition: all 0.3s ease-in-out;
//   background-color: #fcfcfc;
//   padding: 10px;
// }

// .child:hover {
//   transform: scale(1.1);
//   cursor: pointer;
// }

// .child.toMaxBox:hover {
//   transform: scale(1);
// }
.emoving-com-img img {
  margin: 0;
  vertical-align: top;
}

.e-moving-link {
  display: flex;
  align-items: center; /* 垂直置中 */
  justify-content: center;
}

.e-moving-link a {
  display: flex;
  align-items: center; /* 垂直置中 */
  text-decoration: none; /* 移除連結下劃線 */
}

.e-moving-link span {
  margin-right: 10px; /* 調整文字和圖片之間的間距 */
}
</style>
