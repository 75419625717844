<script>
// 引入样式文件
import "@/components/contentPages/viewpoint/gourmetFood/goumetFood.css";
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="shop-box">
    <div class="shop-name">元味美食</div>
    <div class="header-description">
      大腸麵線、麻辣臭豆腐、肉羹麵線、甜不辣、肉圓..等等。
    </div>
    <div class="shop-name-origin">
      老闆良心製作，不加焦糖與色素天然甘甜，遵循原味，因此店名取名為<strong>元味美食</strong>。
    </div>
    <div class="shop-photo-box">
      <img
        src="@/components/contentPages/viewpoint/gourmetFood/images/food-1-1.avif"
        alt="元味美食-1"
      />
      <img
        src="@/components/contentPages/viewpoint/gourmetFood/images/food-1-2.avif"
        alt="元味美食-2"
      />
      <img
        src="@/components/contentPages/viewpoint/gourmetFood/images/food-1-3.avif"
        alt="元味美食-3"
      />
      <img
        src="@/components/contentPages/viewpoint/gourmetFood/images/food-1-4.avif"
        alt="元味美食-4"
      />
    </div>
    <div class="final-comment">
      <div>
        <strong>自製超Q彈肉圓</strong>(不需跑到彰化北斗吃肉圓,不輸北斗肉圓)。
      </div>
      <div>
        <strong>大腸麵線</strong>(吃了很多家,還是原味好,大溪:元味美食超棒)。
      </div>
      <div>
        <strong>麻辣臭豆腐</strong>(不需跑到深坑吃臭豆腐,不輸深坑臭豆腐)。
      </div>
      <div>
        <strong>肉羹麵線</strong
        >、<strong>甜不辣</strong>...等，自己點著吃，自己感受吧!
      </div>
    </div>
    <div class="shop-point-map">
      <div>地址:<strong> 桃園市大溪區仁和路一段200號</strong></div>
      <div>電話訂餐專線:<strong>0933-656-988</strong></div>
    </div>
    <div class="tail-note">
      <div>在地大溪人一定要吃的在地美食，真材實料。</div>
      <div>
        == 平價又超好吃的大溪美食,有到大溪一定要來吃吃喔! 没吃到就枉到大溪喔! ==
      </div>
    </div>
    <hr class="style-two" />
    <div class="nearby-attractions">
      <div class="nearby-attractions-note">
        <div>吃飽了到<strong>仁和宮</strong>走走吧!</div>
        <div>
          (供奉之主神為開漳聖王，
          為全省第一間供奉開漳聖王之廟宇，可稱全臺開漳聖王開基祖廟。)
        </div>
      </div>
      <div class="nearby-attractions-point-map"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
