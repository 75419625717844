<script>
// 引入样式文件
import "@/components/contentPages/tabSharing.css";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <li><a href="/company/com-data.html">基本資料</a></li>
        <li class="active"><a href="/company/location.html">地理位置</a></li>
        <li><a href="/company/business-hours.html">營業時間</a></li>
      </ul>
    </div>
    <div id="tab-box">
      <!-- =========================================== -->
      <!--HTML ==============================中欄:這裏要顯示:內容的區域======公司基本資料======================================= -->
      <div class="loc-google-maps my-flex-XY-center">
        <a
          rel="nofollow"
          href="https://goo.gl/maps/mGuSzo37CUBZLHMK8"
          target="_blank"
          title="點我:Google地圖導航"
          ><img
            class="loc-img my-img-hover"
            src="@/components/contentPages/comData/images/location.avif"
            alt="地理位置"
        /></a>
      </div>
      <!-- 
=====以下開啓google-maps 控制台會跳出問題=====
[Violation] Added non-passive event listener to a scroll-blocking <部分> 事件. 
.[違規] 向滾動阻塞 <部分> 事件添加了非被動事件偵聽器。
Consider marking event handler as 'passive' to make the page more responsive. See <網址>
考慮將事件處理程序標記為“被動”以使頁面響應更快。 見<網址>
 -->
      <!--  -->
      <div class="loc-google-maps my-flex-XY-center">
        <iframe
          class="iframe"
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7238.183549249308!2d121.27578!3d24.894851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681811b39e1137%3A0x3d2a401751a844b4!2z6Yyh5piO57ag6IO96Zu75YuV6LuK!5e0!3m2!1szh-TW!2stw!4v1682766255950!5m2!1szh-TW!2stw"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
      </div>

      <h3>(麥當勞大溪員林店旁)</h3>
      <!-- =========================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loc-img {
  /* width: 600px; */
  max-width: 600px;
  max-height: 450px;
  margin: 30px 0;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.loc-google-maps {
  width: 100%;
  /* position: relative;
    padding-bottom: 75%; */
  /* 此為地圖長寬比 height: 0; */
  /* overflow: hidden; */
}

.loc-google-maps iframe {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
</style>
