<script>
export default {
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="mode-box">
    <div class="top-car-img-box">
      <!-- 車種圖 -->
      <img
        src="@/components/contentPages/brand/e-moving/images/ginsan125.png"
        alt="勁炫-125"
      />

      <!-- 車種 -->
      <hr class="style-two" />
      <div class="heading"><span>勁炫-125</span></div>

      <!-- 頭:說明:等級 -->
      <div class="header-description">
        普通重型 : 超級快充、可家充、電池月租99、CVT無段變速。
      </div>
    </div>
    <hr class="style-two" />
    <div class="emoving-com-img">
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220803101640.jpg"
        alt="勁炫-125"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20220803095926.jpg"
        alt="勁炫-125"
      />
      <img
        src="https://www.e-moving.com.tw/emwspublished/C0002/pc20230116182948.jpg"
        alt="勁炫-125"
      />
    </div>
    <!--  -->
    <hr class="style-two" />
    <div class="e-moving-link">
      <a
        href="https://www.e-moving.com.tw/JX"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/components/contentPages/brand/e-moving/images/e-moving.png"
          alt=""
        />
      </a>
    </div>
    <!--  -->
  </div>
</template>

<style lang="scss" scoped></style>
