<script>
import { onMounted } from "vue";
import iframeAutoSize from "@/components/contentPages/utils/iframeAutoSize.js";

export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    // 在 onMounted 钩子中调用 iframeAutoSize 来自动调整 iframe 大小
    onMounted(() => {
      iframeAutoSize(); // 调用 iframeAutoSize 函数
    });

    return { props };
  },
};
</script>

<template>
  <div>
    <div class="tab-sharing-menu-box">
      <ul class="tab-title mobile-920" v-show="!props.isMenuOpen">
        <!-- 注意:id=路徑_檔名 (這是inner-menu.js 與主程式 要套用)-->
        <li>
          <a href="/motor-test/written-test.html">筆試</a>
        </li>
        <li class="active"><a href="/motor-test/road-test.html">路考</a></li>
        <!-- <li><a href="#businessHours">營業時間</a></li>-->
      </ul>
    </div>
    <div id="tab-box">
      <!-- ===================================== -->
      <h2>機車路考</h2>
      <h3>
        依據現行道路交通安全規則第60條，考領輕型或普通重型機車駕駛執照須年滿18歲(成年)。
      </h3>
      <hr class="style-two" />
      <div class="projectText">
        <p>
          ◎普通重型暨輕型機車新式路考考驗科(項)目場地圖。含:監理所機車路考說明。
        </p>
      </div>
      <!--  
<div class="iframe-box">
<iframe width="560" height="315" src="https://www.youtube.com/embed/CsGlEgu62bE" title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen></iframe>
</div>
-->
      <div class="iframe-box">
        <iframe
          ref="myIframe"
          class="iframe"
          scrolling="no"
          src="https://docs.google.com/presentation/d/e/2PACX-1vToIAhoKKFlFdmLd59L4gIoZIOISPPcfydeb5wYySylUgx-RiyPK2JmkMHYHxeSHJG8G24ruX8QaD0o/embed?start=true&loop=true&delayms=3000"
          frameborder="0"
          width="960"
          height="570"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <div class="directions">
          <strong>龍潭區機車考照練習場:</strong> 桃園市龍潭區
          <a
            style="color: blue"
            href="https://www.google.com/maps/place/325%E6%A1%83%E5%9C%92%E5%B8%82%E9%BE%8D%E6%BD%AD%E5%8D%80%E6%B0%B8%E5%B9%B3%E8%B7%AF80%E8%99%9F/@24.828914,121.2446242,17z/data=!4m6!3m5!1s0x34683d93f1dbb7d7:0x50c15eb98868c029!8m2!3d24.8288697!4d121.2461549!16s%2Fg%2F11jv815cck"
            target="_blank"
            rel="大平里集會所"
          >
            <strong>大平里集會所</strong>
          </a>
          (桃園市龍潭區永平路80號)
        </div>
        <div class="directions">
          該集會所無圍牆,不需打電話預約,隨時可練習,方便需要之民眾,請多加利用與愛護環境...感恩!
        </div>
      </div>
      <!-- ==================================== -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.iframe-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.projectText {
  margin: 10px 0 5px 0;
  /* width: calc(100% / 1); */
  /* height: 40px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  padding: 0 20px 5px 20px;
  border-radius: 50px;
}

.projectText > p {
  font-size: 16pt;
}

.directions {
  width: 100%;
  text-align: center;
  padding: 5px;
}
</style>
