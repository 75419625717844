<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
  props: {
    toggleMenuShow: {
      type: Function,
      default() {
        () => {};
      },
    },
    isMenuOpen: {
      type: Boolean,
    },
  },
  setup(props) {
    //===當在920像素按下小選單取消.menu-show 時要將Content.vue中的.tab-title小菜單顯示===
    //注意:當在920像素按下小選單(:class="{ 'menu-show': isMenuOpen 控制}")新增.menu-show 後在
    //CSS中的 &.menu-show ~ .tab-title.mobile-920 {display: none;}中控制
    //====這裡是在 isMenuOpen 變為 false 時顯示 .tab-title.mobile-920 元素。=====
    //======在取消 menu-show 時，.tab-title.mobile-920 元素能夠正確顯示。=======
    // 監聽 isMenuOpen 的變化
    // watch(isMenuOpen, (newVal) => {
    //   if (!newVal) {
    //     // 如果 isMenuOpen 被取消，則顯示 .tab-title.mobile-920 元素
    //     showTabTitle();
    //   }
    // });

    // // 添加一個方法來顯示 .tab-title.mobile-920 元素
    // const showTabTitle = () => {
    //   const tabTitle = document.querySelector(".tab-title.mobile-920");
    //   if (tabTitle) {
    //     tabTitle.style.display = "block";
    //   }
    // };

    //=========這是主菜單(.main-menu-a .active )===================
    const route = useRoute();
    const menuItems = [
      { id: "com-news", label: "最新訊息", href: "/news.html" },
      { id: "brand", label: "電車廠牌", href: "/brand" },
      { id: "EMotorbikeSort", label: "二輪分類", href: "/tworou-of-class" },
      { id: "subsidize-data", label: "補助相關", href: "/subsidize-data.html" },
      { id: "motorcycleTest", label: "機車科試", href: "/motor-test" },
      { id: "viewpoint", label: "大溪景食", href: "/viewpoint" },
      {
        id: "government-body",
        label: "政府機關",
        href: "/government-body.html",
      },
      { id: "Web-Related", label: "網頁相關", href: "/web-related.html" },
      // 其他菜单项 ...
    ];
    const activeTab = ref(null);
    // 1.===检测当前 URL:href: "/tworou-of-class" ，设置初始的 activeTab
    // const checkInitialTab = () => {
    //   const currentPath = window.location.pathname;
    //   const menuItem = menuItems.find((item) => item.href === currentPath);
    //   if (menuItem) {
    //     activeTab.value = menuItem.id;
    //   }
    // };
    //2.====檢查URL如包函有  "/tworou-of-class" 該字串 則: class="main-menu-a"  都加上 active
    //2.==如:http://localhost:8080/tworou-of-class/regulations(內則包函該字串)
    const checkInitialTab = () => {
      const currentPath = route.path;
      const menuItem = menuItems.find((item) =>
        currentPath.includes(item.href)
      );
      if (menuItem) {
        activeTab.value = menuItem.id;
      }
    };

    // 在页面加载时检测初始的 activeTab
    onMounted(() => {
      checkInitialTab();
    });

    //以下為要改的函數:當URL重載入時,檢測URL 有 "/news 時,則在該頁面加註:active
    const handleMenuItemClick = (tab) => {
      if (activeTab.value === tab) {
        activeTab.value = null; // 取消当前菜单项的 active
      } else {
        activeTab.value = tab; // 设置为当前菜单项的 active
        if (props.isMenuOpen) {
          //手機模式:下拉式主菜單被點擊時,則收回下拉式主菜單並顯示內容頁的上層選單
          //判斷式:class="main-nav-menu desktop-menu"假如有.menu-show (顯示下拉式菜單)才進來取消.menu-show
          //說明:這判斷式是確保在手機模式下運行,不然在PC版點擊主菜單時會運作異常(class="tab-title mobile-920"內容頁的上層選單會被隱藏。)。
          //注意:class="main-nav-menu desktop-menu"假如有.menu-show 則 class="tab-title mobile-920"內容頁的上層選單會被隱藏。
          props.toggleMenuShow(); // 切换 isMenuOpen 的状态
        }
      }
    };
    //2.==
    const isMenuItemActive = (href) => {
      return route.path.includes(href);
    };

    return {
      props,
      menuItems,
      activeTab,
      handleMenuItemClick,
      isMenuItemActive,
    };
  },
};
</script>

<template>
  <!-- ==============================頁頭:這裏要顯示:公司與選單的區域============================================== -->
  <div id="headerPage">
    <nav class="main-nav">
      <div class="main-nav-company-icon">
        <div class="company-animation-box company-animation-inbox">
          <a class="nav-link-company-png" href="/company/com-data.html"
            ><img
              class=""
              src="@/assets/images/CJ-company/cj-log-company.webp"
              alt="啓鉅電動車"
          /></a>
        </div>
      </div>
      <div
        class="main-nav-menu desktop-menu"
        :class="{ 'menu-show': props.isMenuOpen }"
      >
        <div class="mobile-menu">
          <!--行動版開啟/關閉選單-->
          <div class="mobile-btn">
            <img
              id="mobileMenuOpen"
              src="@/assets/images/mobile/mobile-menu-open-48.png"
              alt="開啟選單"
              @click="toggleMenuShow"
            />
            <!-- 以下標註起來的是因為手機無法作動(PC版OK) -->
            <!-- <img id="mobileMenuClose" src="/images/mobile/mobile-menu-close-48.png" alt="關閉選單"></img> -->
          </div>
          <!-- <a class="mobile-close" href="#">
                             <img src="/images/mobile/mobile-menu-btn.png" alt="關閉選單" width="20px" height="20px">
                         </a> -->
        </div>
        <!-- 靜態式網頁(先不求美化,先以學習html,CSS,javascript能架靜態網站為主。)
                        1.將做好的內容網頁名稱,檔名寫入 id=""中即可,js即會在使用者點擊時載入該內容頁。
                        2.因:目前該方式,至始至終皆是利用index.html唯一檔做引入內容頁。
                        問題:
                        1.無法切換網址列。2.網址列也無參數帶入。3.搜尋機器可能也無法將每頁搜尋與定位。
                        解決:(等全部寫好後,再拆分)
                        1.將各頁回歸基本,停止使用(先標註掉)js之使用者點擊,改採href=""路徑讓使用者點擊各頁。
                        2.將頭,尾檔拆成1份以供各分頁引用,以防要修改好几個分頁的頭尾資料。
                        3.將每頁寫meta...給搜尋機器亦方能精準網頁定位。href="?tid=com_news"
                     -->
        <ul class="menu-item">
          <li
            class="main-menu"
            v-for="menuItem in menuItems"
            :key="menuItem.id"
          >
            <router-link
              class="main-menu-a"
              :class="{ active: isMenuItemActive(menuItem.href) }"
              :to="menuItem.href"
              @click="handleMenuItemClick(menuItem.id)"
            >
              {{ menuItem.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<style lang="scss">
/* ========================================================================================================= */
/* ==============================頁頭:這裏要顯示:公司與選單的區域============================================== */
/* ========================================================================================================= */
#headerPage {
  /*====使用 grid-area排版,  先命名headerPage區塊( class="headerPage")====*/
  /* grid-area: headerPage; */
  /* ================= */
  /* position: sticky;
    top: 0px; */
  /*設定 position: sticky 的方塊來說，使用者一路往下滑、
        方塊往上跑的時，當方塊碰到 scrolling ancestor，也就是
         container 的時候，就會「黏在」他上面。當然「黏的」的位置，
         可以透過 top, right, bottom, left 來決定。
        */
  position: fixed;
  top: 0;
  /* left: 0; */

  /* fixed 浮動定位 會讓網頁元素固定在瀏覽器視窗的某個位置，
    即使網頁滾動也不會改變。元素框格的位置用浮動定位 top, right, bottom, left 設定 */

  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* absolute 絕對定位(會跟網頁一起動)元素框格的位置用 top,  right,  bottom, left 設定，其距離是相對於父元素內容區邊界。 */

  /* ===設定標頭頁的總寛與高=== */
  /* 總寛度不要設, 設下去則(會太寛)視窗下方會多了,橫拉條 */
  width: 100vw;
  height: 40px;
  /* -------------頭頁NAV容器:背景色---------------- */
  background: rgb(239, 241, 237);
  /* 導航欄容器下框劃一條黑線 */
  /* border-bottom: 1px #333333 solid; */
  /* border-bottom: 1px #868e96 solid; */
  border-bottom: 1px #e0e0e0 solid;
  /* 頭頁導航欄容器與內容菜單下框與尾頁上框之陰影相同,,*/
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

  /* 將圖層向上提升至1000層(以防止被盖圖) */
  z-index: 1000;

  > .main-nav {
    /* ========頭頁NAV容器:放 <div>公司ICON 與 <div>導航欄===>div>a>img====== */
    /* background: rgb(239, 241, 237); */
    display: flex;
    /*--- 內框邊左右邊為10PX為界,再放兩個div容器: 公司ICON 與 導航欄 ---*/
    padding: 0 30px 0 20px;
    width: 100%;

    > .main-nav-company-icon {
      /* -===========-DIV公司容器:放 公司ICON====>a>img-===============- */
      padding: 2px 0 2px 0;
      /*==== CSS 圖片水平置中margin外間距 ====*/
      /* margin: auto; */
      /* 將公司HOME-img圖之 div容器設定為:自動(如果定死寛度,則導航欄的 div 容器無法正常靠右 */
      width: auto;
      /* ===使用flex將該div容器中的a容器 置中=== */
      display: flex;
      /*將彈性項目對齊到容器的中心(靠左對齊)。*/
      justify-content: left;
      /*使彈性項目在容器中間對齊(垂直對齊)。*/
      align-items: center;
      > .company-animation-box {
        /* ---------公司動畫框用---------- */
        display: flex;
        /*將彈性項目對齊到容器的中心(水平對齊)。*/
        justify-content: center;
        /*使彈性項目在容器中間對齊(垂直對齊)。*/
        align-items: center;
        &.company-animation-inbox {
          /* ---------公司動畫框用---------- */
          width: 180px;
          height: 37px;
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          &::before {
            content: "";
            top: 2px;
            left: 2px;
            position: absolute;
            width: 176px;
            height: 33px;
            border-radius: 5px;
            background: rgb(239, 241, 237);
            z-index: 1;
          }
          &::after {
            content: "";
            position: absolute;
            left: -60px;
            top: -130px;
            width: 300px;
            height: 300px;
            background-repeat: no-repeat;
            background-size: 50% 50%, 50% 50%, 50% 50%, 50% 50%;
            background-position: 0 0, 100% 0, 100% 100%, 0 100%;
            background-image: linear-gradient(#399953, #399953),
              linear-gradient(#fbb300, #fbb300),
              linear-gradient(#d53e33, #d53e33),
              linear-gradient(#377af5, #377af5);
            animation: rotate 4s linear infinite;
          }
          @keyframes rotate {
            100% {
              transform: rotate(1turn);
            }
          }
        }
        > .nav-link-company-png {
          /* -----------a公司容器:放 公司ICON====>img--------------- */
          width: auto;
          /* ===使用flex將該div容器中的a容器 置中=== */
          display: flex;
          /*將彈性項目對齊到容器的中心(水平對齊)。*/
          justify-content: center;
          /*使彈性項目在容器中間對齊(垂直對齊)。*/
          align-items: center;
          /* 設置在本a容器向內5px像素,再放置img圖 */
          /* padding: 5px; */

          > img {
            /* -----------img容器:放 公司ICON====--------------- */
            /* ===使用flex將該div容器中的a容器 置中=== */
            display: flex;
            /*將彈性項目對齊到容器的中心(靠左對齊)。*/
            justify-content: left;
            /*使彈性項目在容器中間對齊(垂直對齊)。*/
            align-items: center;
            height: 30px;
            width: 170px;
            /* display: block; */
            /* position: absolute; */
            /* ====讓公司圖片維持等比例縮放 ==在main.css中已設定width: 100%;height: 100%;==*/
            /* max-width: 100%;
    max-height: 100%; */
            z-index: 100;
          }
        }
      }
    }

    > .main-nav-menu {
      /* ===============導航欄的 div 容器======>ul>li>a====================== */
      /* ===設定導航欄的 div 容器總寛高=== */
      /* ---寛度要設減去div公司容器20VW=80vw(如果没設定該固定寛度,導航欄無法靠右排)--- */
      width: 100%;
      /* height: 100%; */
      display: flex;
      /*(垂直對齊)*/
      /* align-items: center; */
      /*將彈性項目對齊到容器的末端。*/
      /* justify-content: flex-end; */
      &.desktop-menu {
        /* 電腦版先顯示(整個區塊都是我的) */
        display: block;

        @media screen and (max-width: 920px) {
          /*當點下行動MENU後body.menu-show時將內容頁tab頁籤的選單隱藏*/
          &.menu-show ~ ul.tab-title.mobile-920 {
            display: none;
          }

          &.menu-show .menu-item {
            /*當點下行動MENU後body.menu-show時將主菜單顯示*/
            /* 指定開啓後的最大高度 */
            max-height: 500px;
            /* 選單開啟後,選單頂部所處位置 */
            top: 40px;
            height: auto;
            /* 設置透明度:0完全透明~1完全不透明 */
            opacity: 0.98;
          }
        }
      }

      > .mobile-menu {
        /* =============================================================== */
        /* ======================手機模式的基本設定======================== */
        /*  這個主外框:先把寛度拉開至100%,子框<a>寛度自動100%,後才能將<img>元素彈性項目對齊到容器的末端。*/
        /* flex: auto; */

        /* width: auto; */
        display: flex;
        /* flex-direction: row-reverse; */
        /*(垂直對齊)*/
        align-items: center;
        /*將彈性項目對齊到容器的末端。將 Flex 元素靠右對齊。*/
        justify-content: flex-end;
        // .mobile-menu {
        /* 手機版先關閉 */
        display: none;
        @media screen and (max-width: 920px) {
          /*
    ==============================
       ===  手機版-菜單圖顯示 ====
    ==============================
    */
          /* 注意:這裏如使用 display:block 則會造成，header.css中.mobile-men所設display: flex之置中靠右將無法作動 */
          /* display: block; */
          display: flex;
        }
        // }
        > .mobile-btn {
          border: 1px #e0e0e0 solid;
          border-radius: 5px;
          width: 38px;
          height: 38px;
          &:hover {
            cursor: pointer;
          }

          > img {
            width: 38px;
            height: 38px;
          }
        }
      }

      > .menu-item {
        /* ----------------  導航欄的 ul 容器---->li>a--------------------- */
        /* 設定上下為2px為間隔 */
        padding: 2px 0;
        /* ==使用 display: flex; 加 align-items: center;  justify-content: flex-end;讓ul中li元素垂直置中並靠右== */
        /* 將ul容器設置為display: flex，
    這意味著子項(li)成為彈性項。在每個項目之間放置等量的空間
    ，左側和右側項目與Flex 容器的邊緣齊平。即為水平放置。 */
        display: flex;
        /* 水平堆疊彈性項目（但從右到左）。 */
        /* flex-direction: row-reverse; */
        /*將彈性項目對齊到容器的末端。*/
        justify-content: flex-end;
        /*(垂直對齊)*/
        align-items: center;
        /* justify-content: center; 將彈性項目對齊到容器的中心(水平對齊)。*/
        /* column-gap:列之間;  row-gap:行之間;  gap:每列/行之間; 的空間。*/
        /* gap: 10px; */
        /* row-gap: 10px; */
        /* column-gap: 10px; */
        height: 100%;
        @media screen and (max-width: 920px) {
          /* 改垂直排列 */
          flex-direction: column;
          /*隱藏選單開始*/
          max-height: 0;
          overflow: hidden;
          /*隱藏選單end*/
          position: absolute;
          /*追隨所處容器之上層容器的相對位置。*/
          /*讓選單從左到右延伸start*/
          right: 0;
          left: 0;
          /*讓選單從左到右延伸end*/

          /*把menu-item位置移到.headerPage下方。*/
          /* z-index: 100; */
          transition: all 1.5s;
          height: 0;
          opacity: 0;
          > li {
            /* 行動MENU排版-li的樣式 */
            float: none;
            border-bottom: 1px solid rgb(153, 153, 153);
            background: rgb(239, 241, 237);
            width: 100%;
            > a {
              /* 行動MENU排版-a的樣式 */
              /* 寛度撐到最開,以方便行動版點擊 */
              /* width: 100%; */
              text-align: center;
            }
          }
        }
        > .main-menu {
          /* ----------------  導航欄的 li 容器---->a--------------------- */
          /*以下================ 確保鼠標: hover 作用區域超過subMenu選單 ========================*/
          /* 這裏的設定為: hover的高度要大於headerPage的總高度40PX, 系為了確保鼠標: hover 作用區域超過subMenu選單, 否刖鼠標要移動至subMenu選單間(有空隙:無法連接作用)subMenu選單將自動關閉。 */
          /*(寬高設定作用在content內容範圍-預設)*/
          box-sizing: content-box;
          /* 這裏的設定為: hover的高度要大於headerPage的總高度40PX, 系為了確保鼠標: hover 作用區域 */
          height: 38px;
          /* 以上----------------確保鼠標: hover 作用區域超過subMenu選單--------------------------------------------- */

          /* 設定固定寛度,使字體hover變大時,不會造成欄位推擠 */
          width: 80px;
          @media screen and (max-width: 920px) {
            width: 100%;
          }

          padding: 0 0;
          /* margin: 0 5px; */

          /* 以下為字體hover變大時,以置中方式變大(而不會有左側向右放大感) */
          /* ==使用 display: flex; 加 align-items: center與 justify-content: center;; 讓li中a元素垂直與水平置中== */
          display: flex;
          /*(垂直對齊)*/
          align-items: center;
          /*將彈性項目對齊到容器的中心(水平對齊)。*/
          justify-content: center;
          &:hover .main-menu-a {
            /* 鼠標在主選單上停留時 */
            font-size: 13pt;
            /* text-shadow: 0px 0px 10px rgb(244, 247, 72); */
            /* color: white; */
            /* background-color: #b6b3b3; */
            cursor: pointer;
          }
          > .main-menu-a {
            /* ========導航欄中 a 容器:所有a超連結列表:字體,框..等的設定========= */
            /* 字體-色 */
            color: rgb(113, 119, 68);
            /* 字體-大小 */
            font-size: 12pt;
            /* 字體-粗 */
            font-weight: bold;

            display: block;
            /*====padding CSS 簡寫屬性控制元素所有四條邊的內邊距區域(內框)====*/
            /* padding內邊距: 上下框線 左右框線; */
            padding: 8px 3px;
            /* 取消超連結底線 */
            text-decoration: none;

            /* text-align: center; */
            line-height: 100%;

            &:hover {
              /* 鼠標在主選單上停留時 */
              font-size: 13pt;
              /* text-shadow: 0px 0px 10px rgb(244, 247, 72); */
              /* color: white; */
              /* background-color: #b6b3b3; */
              cursor: pointer;
            }
            &.active {
              /* 主選單作動中 */
              background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.65),
                rgba(0, 0, 0, 0.75),
                rgba(0, 0, 0, 0.65),
                rgba(0, 0, 0, 0)
              );
              /* background-color: rgb(173, 173, 173); */
              /* border: 1px solid rgb(153, 153, 153); */
              color: #fafafa;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.sub-menu {
  /* (display 同一類中只能一個,不可重複設)將容器設置為display: flex，
    這意味著子項成為彈性項。在每個項目之間放置等量的空間
    ，左側和右側項目與Flex 容器的邊緣齊平。即為水平放置。 */
  /* display: flex; */
  display: none;

  /* ==絶對位址== */
  position: absolute;
  top: 40px;
  /* right: 0; */
  left: 0;
  /* 子選單若設 width: 100vw;則視窗下方會多一條卷軸(VW是不包含卷軸在內,再加卷軸寛度將超過) */
  /* 子選單若設 width: 100%;則視窗下方不會多一條卷軸 */
  width: 100%;
  /* height: auto; */
  /* border: 1px solid #73ad21; */
  /* 讓子選單全數靠右排列 */
  justify-content: right;

  background: rgb(239, 241, 237);

  padding: 5px;
  /* column-gap:列之間;  row-gap:行之間;  gap:每列/行之間; 的空間。*/
  /* gap: 0px; */
  > li {
    width: 120px;
    padding: 5px;
    /* margin: 5px; */
    /* background-color: #73ad21; */
  }
}

.sub-menu a {
  /* margin: 5px; */
  /* display: block; */
  display: flex;
  /*將彈性項目對齊到容器的中心(水平對齊)。*/
  justify-content: center;
  /*使彈性項目在容器中間對齊(垂直對齊)。*/
  align-items: center;

  /* background-color: burlywood; */
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(21, 110, 69);
  font-weight: bold;
  font-size: 12pt;
}

/* 選單作動中 */
/* .active { */
/* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)); */
/* background-color: rgb(173, 173, 173); */
/* border: 1px solid rgb(153, 153, 153); */
/* color: #FAFAFA; */
/* border-radius: 5px; */
/* } */

/* .item-1:hover .sub-menu { */
/* 將容器設置為display: flex，
    這意味著子項成為彈性項。在每個項目之間放置等量的空間
    ，左側和右側項目與Flex 容器的邊緣齊平。即為水平放置。 */
/* display: flex; */

/* } */
</style>
