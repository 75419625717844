<script>
import { useRouter } from "vue-router";
import { ref, onBeforeUnmount } from "vue"; //頁面跳轉倒數計時
export default {
  setup() {
    const router = useRouter();
    const countdown = ref(3); // 初始化倒計時秒數

    const countdownInterval = setInterval(() => {
      countdown.value--; // 每秒遞減倒計時
      if (countdown.value === 0) {
        clearInterval(countdownInterval); // 倒計時結束，清除計時器
        router.push("/news.html"); // 跳轉到目標頁面
      }
    }, 1000); // 每隔 1000 毫秒（1 秒）執行一次

    // 在組件卸載前清除計時器，以防止內存洩漏
    onBeforeUnmount(() => {
      clearInterval(countdownInterval);
    });

    return {
      countdown,
    };
  },
};
</script>

<template>
  <!-- ==============================中欄:這裏要顯示:內容的區域============================================== -->
  <div id="contentBox" class="my-flex-XY-center">
    <div id="contentPage">
      <div class="notfound-box">
        <div class="notfound">
          <span>
            <span style="color: rgb(133, 3, 3)">404</span> <br />
            找不到網頁 <br />
          </span>
        </div>
        <div class="countdown-second">自動跳轉: {{ countdown }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* =============================================== */
/* ========== 中欄:這裏要顯示:內容的區域 =========== */
/* =============================================== */
/* ===毎頁內容頁之共通設置(毎頁內容頁的共用窗口)=== */
#contentBox {
  padding: 50px 0 50px 0;
  width: 100%;
  /* position: relative; */
  /* padding: 75px 10px 10px 10px; */
  /* margin-top: 75px; */
  /* display: flex;
    justify-content: center; */
  /* 水平置中 */
  /* align-items: center; */
  /* 垂直置中 */

  > #contentPage {
    /* grid-area: contentPage; */

    /* ============================================================================== */
    /* ========== @media screen and (max-width:700px) {display: grid; =============== */
    /*========================= 左側15VW  中間70VW 右側15VW ==========================*/
    /* ============ vw表示的是view width，也就是螢幕可是範圍寬度的百分比。 =============*/

    padding: 10px;
    /* display: flex;
    align-items: center;
    justify-self: center; */

    /* background-color: rgb(165, 165, 231); */
    /* margin: 1px; */
    // height: 600px;

    /* 顯示左側框線與背景色...===調校用=== */
    /* border: rgb(0, 17, 255) solid 1px; */
    /* background-color: #5d48aa; */
    width: 70vw;
    @media screen and (max-width: 920px) {
      width: 100%;
    }
    > .notfound-box .notfound {
      display: flex;
      justify-content: center;
      align-items: center;
      //   transform: translateY(-5px);
    }

    > .notfound-box .notfound span {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      text-align: center;
      font-size: 30pt;
      //   padding: 5px 10px 5px 10px;
      //   background-color: rgb(87, 89, 90);
      //   border-radius: 0 0 5px 5px;
      font-weight: bold;
      color: #3d3b3b;
    }
    > .notfound-box .countdown-second {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      text-align: center;
      font-size: 12pt;
      //   padding: 5px 10px 5px 10px;
      //   background-color: rgb(87, 89, 90);
      //   border-radius: 0 0 5px 5px;
      color: #3d3b3b;
    }
  }
}
</style>
